<div class="termin-container">
    <div class="info">
        <div class="info-dates">
            <div class="info-date-in" *ngIf="isValidDate(offer.startDate)">
                <strong>
                    {{offer.startDate.getDate()}}
                    {{('MONTH_SHORT_' + (offer.startDate.getMonth() + 1)) | translate}}
                    {{offer.startDate.getFullYear()}}
                </strong>
                <span>{{('DAY_OF_WEEK_' + (offer.startDate.getDay() + 1)) | translate}}</span>
            </div>
            <div class="info-date-duration">
                <div class="days-info">
                    <span>{{offer.duration + 1}} {{'Tage' | translate}}</span>
                </div>
                <div class="nights-info">
                    <span>{{offer.duration}}</span>
                    <span *ngIf="offer.duration < 2">{{'Nacht' | translate}}</span>
                    <span *ngIf="offer.duration >= 2">{{'Nächte' | translate}}</span>
                </div>
            </div>
            <div class="info-date-out" *ngIf="isValidDate(offer.endDate)">
                <strong>
                    {{offer.endDate.getDate()}}
                    {{('MONTH_SHORT_' + (offer.endDate.getMonth() + 1)) | translate}}
                    {{offer.endDate.getFullYear()}}
                </strong>
                <span>{{('DAY_OF_WEEK_' + (offer.endDate.getDay() + 1)) | translate}}</span>
            </div>
        </div>
        <div class="info-points" *ngIf="airportInfoAvailable">
            <div class="point-container">
                <div class="point-name">{{offer.transports.outbound.departureCode | translate}}</div>
                <div class="point-operator">{{offer.transports.outbound.tourOperator}}</div>
            </div>
            <div class="point-container">
                <div class="point-name">{{offer.transports.inbound.departureCode | translate}}</div>
                <div class="point-operator">{{offer.transports.inbound.tourOperator}}</div>
            </div>
        </div>
        <div class="info-options">
            <div class="info-option" *ngFor="let option of getOfferOptions()" title="{{option | translate}}">
                <i class="ura-offer-card-icon ura-offer-card-icon-done"></i>{{option | translate}}
            </div>
            <div *ngIf="roomGlobalTypeMissing" class="info-option">
                <i class="ura-offer-card-icon ura-offer-card-icon-done"></i>{{offer.hotel.room._}}
            </div>
        </div>

        <div class="button-bar">
            <div class="button" *ngIf="transports" [ngClass]="{active: showFlightInfo}" (click)="toggleFlightInfo()">
                <i class="ura-offer-card-icon ura-offer-card-icon-fly"></i>
                <span>
                    {{'Vorraussichtliche Flugzeiten' | translate}}
                    <i class="ura-offer-card-icon ura-offer-card-icon-close"></i>
                </span>
            </div>
        </div>
    </div>
    <div class="ticket-border-wrapper">
        <div class="ticket-border-first"></div>
        <div class="ticket-border-last"></div>
    </div>
    <div class="actions">
        <div class="operator-container">
            <div class="operator-div">
                <img [attr.src]="getTouropImageUrl(offer.tourOperator)">
            </div>
        </div>
        <div class="price-container">
            <div>
                <span class="price-value" *ngIf="!validation || (validation && validation.status.code === 'XX')">
                    <span>{{'ab' | translate}}</span> {{offer.price | currency:'EUR':'symbol':'1.2-2'}}
                </span>
                <span
                    class="price-value"
                    *ngIf="validation && (validation.status.code === 'BA' || validation.status.code === 'RQ')">
                    {{validation.totalPrice | currency:'EUR':'symbol':'1.2-2'}}
                </span>
            </div>

            <div
                class="price-description"
                *ngIf="!validation || (validation && validation.status.code === 'XX')">
                {{'pro Person' | translate}}
            </div>

            <div
                class="price-description"
                *ngIf="validation && (validation.status.code === 'BA' || validation.status.code === 'RQ')">
                {{'Gesamtpreis' | translate}}
            </div>
        </div>
        <div class="submit-button-wrapper">
            <div class="submit-button" (click)="verifyOffer()" *ngIf="!validation">
                {{'Prüfen' | translate}}
            </div>

            <div
                class="submit-button"
                (click)="submitOffer()"
                [ngClass]="getClassAccordingToValidationType(validation.status.code)"
                *ngIf="validation && (validation.status.code === 'RQ' || validation.status.code === 'BA')">
                <span *ngIf="validation.status.code === 'BA'">{{'Zur Buchung' | translate}}</span>
                <span *ngIf="validation.status.code === 'RQ'">{{'Anfragen' | translate}}</span>
            </div>

            <div
                class="submit-text"
                *ngIf="validation && (validation.status.code !== 'RQ' && validation.status.code !== 'BA')">
                {{'Nicht verfügbar' | translate}}
            </div>
        </div>
    </div>
    <div class="loader-container" [ngClass]="{active: showLoading}">
        <div class="loader-background"></div>
        <div class="loader"></div>
    </div>
</div>

<div class="validation-container" *ngIf="validation" [ngClass]="getClassAccordingToValidationType(validation.status.code)">
    <div class="validation-icon">
        <!-- TODO: add a new validation-icon component -->
        <i class="ura-offer-card-icon ura-offer-card-icon-available" *ngIf="validation.status.code === 'BA'"></i>
        <i class="ura-offer-card-icon ura-offer-card-icon-on-request" *ngIf="validation.status.code === 'RQ'"></i>
        <i class="ura-offer-card-icon ura-offer-card-icon-unavailable" *ngIf="validation.status.code === 'XX'"></i>
    </div>
    <div class="validation-text" *ngIf="validation.status.code">
        {{'VALID-' + validation.status.code | translate}}
    </div>
</div>

<div class="flight-info-container" *ngIf="showFlightInfo && isFlightInfoAvailable">
    <header>
        <h4>{{'Voraussichtliche Flugzeiten' | translate}}</h4>
    </header>

    <div class="flight-bounds">
        <div class="flight-bound" *ngFor="let transport of transports.out">
            <div class="bound-name">{{'Hinflug' | translate}}:</div>
            <div class="flight-table">
                <div class="row row-hidden">
                    <div class="cell cell-title">{{'Datum' | translate}}</div>
                    <div class="cell">{{offer.startDate | date: 'EEE, dd.MM.y'}}</div>
                </div>
                <div class="row-union">
                    <div class="row">
                        <div class="cell cell-title">{{'Abflughafen' | translate}}</div>
                        <div class="cell cell-point">
                            {{transport.departureCode | translate}}
                            ({{transport.departureCode}})
                        </div>
                    </div>
                    <div class="row">
                        <div class="cell cell-title">{{'Zielflughafen' | translate}}</div>
                        <div class="cell cell-point">
                            {{transport.destinationCode | translate}}
                            ({{transport.destinationCode}})
                        </div>
                    </div>
                </div>
                <div class="row-union row-union-min">
                    <div class="row">
                        <div class="cell cell-title">{{'Abflugzeit' | translate}}</div>
                        <div class="cell">
                            <span class="text-desktop-only">{{offer.startDate | date: 'dd.MM.y'}} /</span>
                            {{transport.departureTime}} {{'Uhr' | translate}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="cell cell-title">{{'Ankunftszeit' | translate}}</div>
                        <div class="cell">
                            <span class="text-desktop-only">{{offer.startDate | date: 'dd.MM.y'}} /</span>
                            {{transport.arrivalTime}} {{'Uhr' | translate}}
                        </div>
                    </div>
                </div>
                <div class="row row-min">
                    <div class="cell cell-title">{{'Airline' | translate}}</div>
                    <div class="cell">{{transport.carrierCode | translate}}</div>
                </div>
                <div class="row row-min">
                    <div class="cell cell-title">{{'Flugnummer' | translate}}</div>
                    <div class="cell">{{transport.flightId}}</div>
                </div>
            </div>
        </div>
        <div class="flight-bound" *ngFor="let transport of transports.in">
            <div class="bound-name">{{'Rückflug' | translate}}:</div>
            <div class="flight-table">
                <div class="row row-hidden">
                    <div class="cell cell-title">{{'Datum' | translate}}</div>
                    <div class="cell">{{offer.endDate | date: 'EEE, dd.MM.y'}}</div>
                </div>
                <div class="row-union">
                    <div class="row">
                        <div class="cell cell-title">{{'Abflughafen' | translate}}</div>
                        <div class="cell cell-point">
                            {{transport.departureCode | translate}}
                            ({{transport.departureCode}})
                        </div>
                    </div>
                    <div class="row">
                        <div class="cell cell-title">{{'Zielflughafen' | translate}}</div>
                        <div class="cell cell-point">
                            {{transport.destinationCode | translate}}
                            ({{transport.destinationCode}})
                        </div>
                    </div>
                </div>
                <div class="row-union row-union-min">
                    <div class="row">
                        <div class="cell cell-title">{{'Abflugzeit' | translate}}</div>
                        <div class="cell">
                            <span class="text-desktop-only">{{offer.endDate | date: 'dd.MM.y'}} /</span>
                            {{transport.departureTime}} {{'Uhr' | translate}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="cell cell-title">{{'Ankunftszeit' | translate}}</div>
                        <div class="cell">
                            <span class="text-desktop-only">{{offer.endDate | date: 'dd.MM.y'}} /</span>
                            {{transport.arrivalTime}} {{'Uhr' | translate}}
                        </div>
                    </div>
                </div>
                <div class="row row-min">
                    <div class="cell cell-title">{{'Airline' | translate}}</div>
                    <div class="cell">{{transport.carrierCode | translate}}</div>
                </div>
                <div class="row row-min">
                    <div class="cell cell-title">{{'Flugnummer' | translate}}</div>
                    <div class="cell">{{transport.flightId}}</div>
                </div>
            </div>
        </div>
    </div>

    <i class="ura-offer-card-icon ura-offer-card-icon-close flight-close" (click)="toggleFlightInfo()"></i>
</div>
