
        <ng-container *ngIf="loading"><urn-preload-spinner></urn-preload-spinner></ng-container>
        <ng-container *ngIf="!loading">
            <h1 md-dialog-title>Cookies</h1>
            <div md-dialog-content>{{content}}</div>
            <div class="cookies-dialog-actions" md-dialog-actions>
                <div *ngIf="acceptButton" class="accept-button" (click)="acceptAndClose()">{{acceptButtonLabel}}</div>
                <div *ngIf="closeButton" class="close-button" (click)="close()">{{closeButtonLabel}}</div>
            </div>
        </ng-container>
    