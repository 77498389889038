<div class="map-box" [@mapVisible]="mapState" (@mapVisible.start)="resizeMap()">
    <sebm-google-map  [fitBounds]="latLngBounds" [latitude]="lat" [longitude]="lng">
        <div *ngFor="let hotelCard of hotelCards; let i = index;" class="hotel-card-container">
            <sebm-google-map-marker
                    [latitude]="hotelCard.latitude"
                    [longitude]="hotelCard.longitude"
                    [title]="hotelCard.title"
                    (markerClick)="onMarkerClick(i)">
                <sebm-google-map-info-window *ngIf="showHotelInfo" [disableAutoPan]="true">
                    <div class="hotel-card">
                        <div class="title">{{hotelCard.title}}</div>
                        <div class="main-container">
                            <div class="picture-container">
                                <div class="rating-container" *ngIf="hotelCard.rating">
                                    <urn-pie-chart [value]="hotelCard.rating"></urn-pie-chart>
                                </div>
                                <div class="picture"
                                     urnImagePreloader
                                     [preloadingImage]="hotelCard.image"
                                     [failedImage]="failedImage"
                                     [loadingImage]="loadingImage">
                                </div>
                            </div>

                            <div class="info-container">
                                <div class="category-items-wrapper">
                                    <div class="category-item" *ngFor="let i of hotelCard.stars | generateArray"></div>
                                </div>

                                <div class="region">
                                    {{hotelCard.city}}, {{hotelCard.country | translate}}
                                </div>

                                <div class="price" (click)="submitHotel(hotelCard)">
                                    <span class="price-prefix">{{'ab' | translate}}</span>
                                    <span class="price-value">{{hotelCard.price}}</span>
                                    <span class="price-currency">&euro;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </sebm-google-map-info-window>
            </sebm-google-map-marker>
        </div>
    </sebm-google-map>
</div>
