<div class="select-container"
     (click)="clickHandler($event)"
     [ngClass]="{
         active: isActive,
         filled: isFilled,
         error: !isValid,
         disabled: disabled
     }">

    <label class="placeholder" *ngIf="placeholder != null && placeholder.length > 0">{{placeholder}}</label>

    <div class="select-wrapper">
        <header>
            <div class="select-icon-wrapper">
                <i class="ura-select-icon ura-select-icon-menu"></i>
            </div>
            <input #inputReference
                   type="text"
                   class="search-text-input"
                   [(ngModel)]="searchText"
                   readonly="{{disabled}}"
                   placeholder="{{model.displayName}}"
                   (focus)="focusHandler()"
                   (blur)="blurHandler()"
            />
        </header>
        <div class="select-options" #dropdownReference [@dropdownVisible]="selectState">
            <div class="option"
                 *ngFor="let option of (options | filterSelection: searchText); let i = index"
                 [ngClass]="{highlight: i === highlightIndex}"
                 [attr.data-value]="option.value"
                 (click)="selectOption(option)"
            >
                {{option.displayName}}
            </div>
        </div>
    </div>
</div>

<div *ngIf="showErrorMessage" class="error-message-wrapper">
    {{errorMessage}}
</div>
