<div class="trustscore size-xs">
    <div class="value">{{ratingStars}}</div>
    <div class="score-rating-container">
        <div class="score-wrapper">
            <div class="score">{{ratingExpressionLabel | translate}}</div>
            <div class="badges">
                <i class="ty-icon ty-icon-badge neg"></i>
                <i class="ty-icon ty-icon-badge neu"></i>
                <i class="ty-icon ty-icon-badge pos"></i>
            </div>
        </div>
        <div class="rating-wrapper">
            <div class="trustyou-ui rating">
                <div class="rating-units">
                    <span class="rating-unit is-full"
                          *ngFor="let i of fullStars | generateArray"></span>
                    <span class="rating-unit is-half" *ngIf="hasHalfStar"></span>
                </div>
            </div>
            <div class="counter">
                25.023 Bewertungen
            </div>
        </div>
    </div>
</div>
