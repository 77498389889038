<div class="form-row visa-info">
    <div
        *ngFor="let visaGroup of visaResponse?.data?.documentRequirements; let index=index"
        class="visa-group"
    >
        <p class="group-name"><strong>{{visaGroup.name}}</strong></p>
        <div *ngFor="let detail of visaGroup.details">
            {{detail.text}}
            <p *ngFor="let subdetail of detail.details">
                {{subdetail.text}}
            </p>
        </div>
        <div class="infowrapper"
            [@visaInformationState]="isExpanded(index) ? 'visible' : 'hidden'"
        >
            <div *ngFor="let info of visaGroup.information">
                <u>{{info.name}}</u>
                <p *ngFor="let subinfo of info.details">
                    {{subinfo.text}}
                </p>
            </div>
        </div>
        <span class="expand" (click)="toggleVisaInfo(index)">
            ...{{ isExpanded(index) ? 'weniger' : 'mehr' | translate }}</span>
    </div>
</div>
