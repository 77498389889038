<div class="regions-list">
    <header>
        <div class="header-item">{{currentType | translate}}</div>
        <div class="header-item">
            <i class="urn-region-icon urn-region-icon-chevron-right"></i>
        </div>
        <div class="header-item">{{'Regionen' | translate}}</div>
    </header>
    <div class="sort-wrapper">
        <urn-sort (sortOrderUpdated)="onSortOrderChange($event)" [sortOptions]="sortOptions"></urn-sort>
    </div>
    <div class="filter-wrapper">
        <urn-filter-box></urn-filter-box>
        <urn-recent-offers></urn-recent-offers>
    </div>
    <div class="regions-wrapper" *ngIf="!initialLoad">
        <urn-region-tree
            [regions]="regions"
            [sortOrder]="activeSortOrder"
            (onSelectRegion)="submitRegion($event)"
        ></urn-region-tree>
    </div>
    <div class="spinner-wrapper" *ngIf="initialLoad">
        <urn-preload-spinner></urn-preload-spinner>
    </div>
    <div class="error-message-wrapper" *ngIf="!initialLoad && noOffersAvailable">
        <urn-no-offers-message></urn-no-offers-message>
    </div>
</div>
