<div class="leaflet-page">
    <header>
        <h3 class="head-label">{{'Mein Merkzettel' | translate}} ({{leafletItems.length}})</h3>
        <span class="button-delete-all" (click)="deleteAll()">{{'Alle entfernen' | translate}}</span>
        <span class="button-back" (click)="navigateToLastSearch()">{{'Zurück' | translate}}</span>
    </header>

    <div class="sort-container">
        <urn-sort (sortOrderUpdated)="onSortOrderChange($event)" [sortOptions]="sortOptions"></urn-sort>
        <urn-leaflet-pagination
            *ngIf="countOfPages > 1"
            [currentPage]="currentPage"
            [countOfPages]="countOfPages"
            (onChange)="onPageChanged($event)">
        </urn-leaflet-pagination>
    </div>

    <div class="leaflet-items-container">
        <div class="item-info">
            <div class="informer" *ngIf="selectedItem.leafletSearchInfo.destination">
                <i class="ura-leaflet-page ura-leaflet-page-Pin"></i>
                <div>{{selectedItem.leafletSearchInfo.destination}}</div>
            </div>

            <div class="informer" *ngIf="selectedItem.leafletSearchInfo.departures">
                <i class="ura-leaflet-page ura-leaflet-page-airplane"></i>
                <div>{{selectedItem.leafletSearchInfo.departures}}</div>
            </div>

            <div class="informer" *ngIf="selectedItem.leafletSearchInfo.date">
                <i class="ura-leaflet-page ura-leaflet-page-calend"></i>
                <div>{{selectedItem.leafletSearchInfo.date}}<br>{{selectedItem.leafletSearchInfo.duration}}</div>
            </div>

            <div class="informer" *ngIf="selectedItem.leafletSearchInfo.travellers.adults">
                <i class="ura-leaflet-page ura-leaflet-page-person"></i>
                <div>
                    <div>
                        {{selectedItem.leafletSearchInfo.travellers.adults}}
                        {{selectedItem.leafletSearchInfo.travellers.adults === 1 ? 'Erwachsener' : 'Erwachsene' | translate}}
                    </div>
                    <div *ngIf="selectedItem.leafletSearchInfo.travellers.children.length > 0">
                        {{selectedItem.leafletSearchInfo.travellers.children.length}}
                        {{selectedItem.leafletSearchInfo.travellers.children.length === 1 ? 'Kind' : 'Kinder' | translate}}
                    </div>
                </div>
            </div>
        </div>

        <div class="leaflet-items">
            <urn-leaflet-item
                *ngFor="let leafletItem of leafletItemsByPage"
                [style.width]="cardWidth"
                [leafletItem]="leafletItem"
                [selected]="isSelected(leafletItem)"
                (onMouseover)="selectItem($event)"
                (onSelect)="selectItem($event)"
                (onClose)="deleteHotelCard($event)">
            </urn-leaflet-item>
        </div>
    </div>

    <div class="filter-container" *ngFor="let item of groupOptionsHotelCard">
        <div class="title-delimiter">
            <div class="line">
                <div class="title">{{item.groupName | translate}}</div>
            </div>
        </div>

        <urn-leaflet-filter
            [items]="itemsForFilter"
            [(ngModel)]="item.options"
            [itemWidth]="cardWidth"
            (onChange)="filterChange()">
        </urn-leaflet-filter>
    </div>
</div>
