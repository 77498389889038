<div class="hotels-page-container">
    <div class="breadcrumb-wrapper">
        <urn-breadcrumb-menu></urn-breadcrumb-menu>
        <h4 class="offer-count">{{offerCount ? offerCount : ''}} {{'Angebote' | translate}}</h4>
    </div>
    <div class="hotels-page-sort-content">
        <div class="city-select-box-container">
            <urn-city-select-box [cities]="cityItems"></urn-city-select-box>
        </div>
        <div class="sort-container" [class.opened]="mapOpened">
            <div class="map-button-container" (click)="toggleMap()">
                <div class="button" [ngClass]="{active: mapOpened}">
                    <i class="urn-hotel-page-icon"
                       [ngClass]="{'urn-hotel-page-icon-pin': !mapOpened, 'urn-hotel-page-icon-remove-off': mapOpened}">
                    </i>
                    <span>{{'Karte' | translate}}</span>
                </div>
                <div class="mobile-button">
                    <i class="urn-hotel-page-icon urn-hotel-page-icon-down" *ngIf="mapOpened"></i>
                    <i class="urn-hotel-page-icon urn-hotel-page-icon-right" *ngIf="!mapOpened"></i>
                    <span>{{'Hotels in Karte' | translate}}</span>
                </div>
            </div>
            <urn-map [hotelCards]="hotelCards"
                     [showHotelInfo]="true"
                     *ngIf="!initialLoad && !noOffersAvailable"></urn-map>
            <urn-sort (sortOrderUpdated)="onSortOrderChange($event)" [sortOptions]="sortOptions"></urn-sort>
        </div>
    </div>
    <div class="hotels-page-content">
        <div class="filterbox-container">
            <urn-filter-box></urn-filter-box>
            <urn-recent-offers></urn-recent-offers>
        </div>
        <div class="hotel-card-list-container" [class.page-loading]="pageLoading" [@mapVisible]="makeSpaceForMap ? 'opened' : 'closed'">
            <div class="hotel-card-list">
                <div *ngFor="let hotelCard of hotelCards" class="hotel-card-container">
                    <urn-hotel-card [hotelCard]="hotelCard"></urn-hotel-card>
                </div>
            </div>
            <div class="spinner-wrapper"
                 *ngIf="initialLoad || pageLoading"
                 [ngStyle]="preloadSpinnerStyles">
                <urn-preload-spinner></urn-preload-spinner>
            </div>
            <div class="error-message-wrapper" *ngIf="!initialLoad && noOffersAvailable">
                <urn-no-offers-message></urn-no-offers-message>
            </div>
            <div class="pagination-container">
                <urn-pagination *ngIf="!initialLoad && !noOffersAvailable && countOfPages !== 1"
                    [countOfPages]="countOfPages"
                    [currentPage]="currentPage"
                    (onChange)="onPageChanged($event)"></urn-pagination>
            </div>
        </div>
    </div>
</div>
