<header>
    <nav class="modes">
        <div class="icon-container"
             [ngClass]="{'active': currentChartMode === WEATHER_CHART_MODE_TEMPERATURE}"
             (click)="onChangeChartMode(WEATHER_CHART_MODE_TEMPERATURE)">
            <span class="urn-hotelview-weather-icon urn-hotelview-weather-icon-temperature"></span>
        </div>
        <div class="icon-container"
             [ngClass]="{'active': currentChartMode === WEATHER_CHART_MODE_RAIN}"
             (click)="onChangeChartMode(WEATHER_CHART_MODE_RAIN)">
            <span class="urn-hotelview-weather-icon urn-hotelview-weather-icon-rainy"></span>
        </div>
        <div class="icon-container"
             [ngClass]="{'active': currentChartMode === WEATHER_CHART_MODE_SUNNY}"
             (click)="onChangeChartMode(WEATHER_CHART_MODE_SUNNY)">
            <span class="urn-hotelview-weather-icon urn-hotelview-weather-icon-sunny"></span>
        </div>
    </nav>
    <div class="options" *ngIf="currentChartMode === WEATHER_CHART_MODE_TEMPERATURE">
        <div class="checkboxes">
            <div class="checkbox-container">
                <urn-checkbox class="checkbox"
                              [(ngModel)]="isDayTemperatureActive"
                              [disabled]="isDayTemperatureDisabled"
                              (onChange)="onTemperatureFilterChanged()"
                              [labelText]="'Tagestemperatur' | translate">
                </urn-checkbox>
            </div>
            <div class="checkbox-container">
                <urn-checkbox class="checkbox"
                              [(ngModel)]="isWaterTemperatureActive"
                              [disabled]="isWaterTemperatureDisabled"
                              (onChange)="onTemperatureFilterChanged()"
                              [labelText]="'Wassertemperatur' | translate">
                </urn-checkbox>
            </div>
            <div class="checkbox-container">
                <urn-checkbox class="checkbox"
                              [(ngModel)]="isNightTemperatureActive"
                              [disabled]="isNightTemperatureDisabled"
                              (onChange)="onTemperatureFilterChanged()"
                              [labelText]="'Nachttemperatur' | translate">
                </urn-checkbox>
            </div>
        </div>
    </div>
</header>
<div class="chart">
    <urn-chart [chartType]="chartType"
               [datasets]="chartDatasets"
               [labels]="chartLabels"
               [options]="chartOptions">
    </urn-chart>
</div>
