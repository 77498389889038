<div class="sort-wrapper">
    <div class="description-header">
        <div class="device-header">{{'Sortieren nach' | translate}}:</div>

        <div class="mobile-header">
            <div class="active-sorting" (click)="updateActiveSortOrder(activeSortOrder)">
                <span>{{activeSortOrder.title | translate}}</span>
                <i class="urn-sort-icon urn-sort-icon-sort-amount-asc"
                   *ngIf="activeSortOrder.orderDirection === 0"
                ></i>
                <i class="urn-sort-icon urn-sort-icon-sort-amount-desc"
                   *ngIf="activeSortOrder.orderDirection === 1"
                ></i>
            </div>
            <div class="header" (click)="toggleSortOptions()">
                <i class="urn-sort-icon urn-sort-icon-arrow-right" *ngIf="!showSortOptions"></i>
                <i class="urn-sort-icon urn-sort-icon-arrow-down" *ngIf="showSortOptions"></i>
                <span>{{'Sortieren nach' | translate}}:</span>
            </div>
        </div>
    </div>

    <div class="sorting-options" [class.open]="showSortOptions">
        <div class="sorting-option"
           *ngFor="let option of sortOptions;"
           [class.active]="activeSortOrder.type === option.type"
           (click)="updateActiveSortOrder(option)">
            <span>{{option.title | translate}}</span>
            <i class="urn-sort-icon urn-sort-icon-arrow-downward"
               *ngIf="option.orderDirection === 0"
            ></i>
            <i class="urn-sort-icon urn-sort-icon-arrow-upward"
               *ngIf="option.orderDirection === 1"
            ></i>
        </div>
    </div>
</div>
