<div class="filter-wrapper">
    <div class="mobile-header" (click)="toggleFilterOptions()">
        <i class="urn-filter-box-icon urn-filter-box-icon-arrow-right" *ngIf="!showFilterOptions"></i>
        <i class="urn-filter-box-icon urn-filter-box-icon-arrow-down" *ngIf="showFilterOptions"></i>
        <span class="header-text">{{'Detailsuche' | translate}}</span>
    </div>

    <div class="filter-options" [class.open]="showFilterOptions">
        <div class="filter-item" *ngIf="enableCategoryFilter">
            <urn-filter-category (change)="activateFilterSubmit('category')"></urn-filter-category>
        </div>
        <div class="filter-item" *ngIf="enableHygieneFilter">
            <urn-filter-hygiene (change)="activateFilterSubmit('hygiene')"></urn-filter-hygiene>
        </div>
        <div class="filter-item" *ngIf="enableBoardFilter">
            <urn-filter-board (change)="activateFilterSubmit('board')"></urn-filter-board>
        </div>
        <div class="filter-item" *ngIf="enableTransferFilter">
            <div class="filter-item-title">{{'Hotel Transfer' | translate}}</div>
            <urn-filter-transfer (change)="activateFilterSubmit('transfer')"></urn-filter-transfer>
        </div>
        <div class="filter-item">
            <div class="filter-item-title">{{'Veranstalterauswahl' | translate}}</div>
            <urn-operator-filter (change)="activateFilterSubmit('tourOperator')"></urn-operator-filter>
        </div>
        <div class="filter-item" *ngIf="enableRoomFilter">
            <urn-filter-room (change)="activateFilterSubmit('roomType')"></urn-filter-room>
        </div>
        <div class="filter-item" *ngIf="enableRatingFilter">
            <urn-filter-rating (change)="activateFilterSubmit('rating')"></urn-filter-rating>
        </div>
        <div class="filter-item">
            <urn-filter-price (change)="activateFilterSubmit('maxPrice')"></urn-filter-price>
        </div>
        <div class="filter-item" *ngIf="enableAttributesFilter">
            <urn-filter-attributes (change)="activateFilterSubmit('attributes')"></urn-filter-attributes>
        </div>
        <div class="filter-item">
            <div class="button-submit" (click)="submit()">
                {{'Filter anwenden' | translate}}
            </div>
        </div>
    </div>
    <div class="filter-submit" (click)="scrollToSubmit()" [ngClass]="{active: showFloatButton}">
        <i class="urn-filter-box-icon urn-filter-box-icon-arrow-down"></i>
    </div>
</div>
