<div class="languages-container">
    <div class="languages--current">
        <div class="languages--current-button" (click)="togglePopover()" [class.active]="isPopoverVisible">
            <i class="urn-language-popup-icon urn-language-popup-icon-lang-selection"></i>
        </div>
        <div class="languages--current-name">{{currentLanguage.isoCode}}</div>
    </div>
    <div class="languages--popover" [hidden]="!isPopoverVisible">
        <div class="language-list">
            <div class="language-item"
                *ngFor="let languageItem of getLanguages()"
                (click)="selectLanguage(languageItem)"
                [class.active]="languageItem.isoCode === currentLanguage.isoCode"
            >
                <div class="language-icon">
                    <span class="flag-icon flag-icon-{{languageItem.isoCode.toLowerCase()}}"></span>
                </div>
                <div class="language-name">{{languageItem.displayName}}</div>
            </div>
        </div>
    </div>
</div>
