<div class="departure-component"
     [class.dropdown-visible]="isOpened"
     [class.search-active]="searchText.length > 0"
     (keydown)="onKeyDown($event)"
>
    <div class="input-container" (click)="onFocus()">
        <i class="ura-departure-icon" [ngClass]="{
            'ura-departure-icon-airplane': !isOpened,
            'ura-departure-icon-search': isOpened
        }"></i>

        <i class="ura-departure-icon ura-departure-icon-close"
           *ngIf="selectedDepartures.length > 0"
           (click)="removeDepartureSelection()">
        </i>

        <div class="input-wrapper">
            <div class="item-count-container" [hidden]="selectedDepartures.length === 0">
                {{selectedDepartures.length}}
            </div>

            <input
                type="text"
                tabindex="1"
                (focus)="onFocus()"
                [(ngModel)]="searchText"
                placeholder="{{placeholder}}"
            />
        </div>
    </div>

    <div class="dropdown-container" [hidden]="!isOpened">
        <ul class="departures-list" *ngIf="searchText.length === 0">
            <li class="departure-region" *ngFor="let region of departures">
                <header class="departure-region-header">
                    <span>{{region.name | translate}}</span>
                    <div class="title-border"></div>
                </header>

                <ul class="subregions-list">
                    <li class="departure-subregion" *ngFor="let subregion of region.items">
                        <header>
                            <i class="ura-departure-icon"
                               (click)="toggleDepartureList(subregion)"
                               [ngClass]="{
                                   'ura-departure-icon-options': !subregion.opened,
                                   'ura-departure-icon-options-opened': subregion.opened
                               }"
                            ></i>
                            <urn-checkbox [(ngModel)]="subregion.selected"
                                          [labelText]="subregion.name | translate"
                                          (onChange)="selectDepartureRegion(subregion)">
                            </urn-checkbox>
                        </header>

                        <ul class="departures" [@departuresVisible]="getAnimaitonState(subregion.opened)">
                            <li class="departure"
                                *ngFor="let departure of subregion.items"
                            >
                                <urn-checkbox
                                    [(ngModel)]="departure.selected"
                                    [labelText]="departure.name | translate"
                                    (onChange)="onDepartureChange(subregion, departure)"
                                ></urn-checkbox>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>

        <ul class="filtered-departures-list" *ngIf="searchText.length > 0">
            <li class="departure"
                *ngFor="let departure of searchedDepartures"
                (click)="selectSearchedDeparture(departure)"
            >
                <span class="departure-name"
                      [innerHTML]="highlightFoundEntry(
                            departure.name,
                            '<span class=&quot;string-entry&quot;>$&amp;</span>'
                      )">
                </span>
                <span class="departure-region">{{departure.region.name}}</span>
            </li>
            <li class="not-found-message" [hidden]="searchedDepartures.length > 0">
                {{'Keine Ergebnisse gefunden' | translate}}
            </li>
        </ul>

        <div class="button-accept" (click)="hideDropdown()">
            {{'Übernehmen' | translate}}
        </div>
    </div>
</div>
