<div class="detail-search">
    <div class="filter-component-group">
        <div class="filter-component" *ngIf="enableCategoryFilter">
            <urn-filter-category (change)="onCategoryChange($event)"></urn-filter-category>
        </div>
        <div class="filter-component" *ngIf="enableHygieneFilter">
            <urn-filter-hygiene (change)="onHygieneChange($event)"></urn-filter-hygiene>
        </div>
        <div class="filter-component" *ngIf="enableTransferFilter">
            <h4 class="filter-component-title">
                {{'Hotel Transfer' | translate}}
            </h4>
            <urn-filter-transfer (change)="onTransferChange($event)"></urn-filter-transfer>
        </div>
        <div class="filter-component">
            <h4 class="filter-component-title">
                {{'Veranstalterauswahl' | translate}}
            </h4>
            <urn-operator-filter></urn-operator-filter>
        </div>
    </div>
    <div class="filter-component-group">
        <div class="filter-component" *ngIf="enableBoardFilter">
            <urn-filter-board (change)="onBoardChange($event)"></urn-filter-board>
        </div>
        <div class="filter-component" *ngIf="enableRatingFilter">
            <urn-filter-rating (change)="onRatingChange($event)"></urn-filter-rating>
        </div>
        <div class="filter-component">
            <urn-filter-price (change)="onPriceChange($event)"></urn-filter-price>
        </div>
    </div>
    <div class="filter-component-group" *ngIf="enableRoomFilter">
        <div class="filter-component">
            <urn-filter-room (change)="onRoomChange($event)"></urn-filter-room>
        </div>
    </div>
    <div class="filter-component-group" *ngIf="enableAttributesFilter">
        <div class="filter-component">
            <urn-filter-attributes (change)="onAttributesChange($event)"></urn-filter-attributes>
        </div>
    </div>
</div>
