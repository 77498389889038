<div class="board-filter">
    <h3 class="title">{{'Verpflegung' | translate}}</h3>

    <div class="board-options">
        <urn-checkbox *ngFor="let board of boardTypes"
                      [(ngModel)]="board.selected"
                      [labelText]="translateBoardCode(board.code)"
                      (onChange)="setBoard(board)">
        </urn-checkbox>
    </div>
</div>
