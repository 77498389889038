<div class="offer-description-card" [class.hotel-info-open]="isHotelInfoOpened">
    <section class="content">
        <div class="main-content">
            <div class="main-content-wrapper">
                <header class="header-container">
                    <div class="category-content">
                        <div class="category-item" *ngFor="let i of hotelCard.stars | generateArray"></div>
                    </div>
                    <h2 class="hotel-name-content">
                        <span class="hotel-name" title="{{hotelCard.title}}">{{hotelCard.title}}</span>
                    </h2>
                    <h3 class="region-content">
                        <span class="region-name">{{hotelCard.city}}, {{hotelCard.country | translate}}</span>
                    </h3>
                </header>

                <div class="hotel-info-container">
                    <div class="picture-container" [class.image-loaded]="imageLoaded">
                        <div class="picture-content">
                            <img src="assets/images/aspect-ratio-placeholders/square.png">
                            <div class="picture-placeholder"></div>
                            <div class="picture-hotel"
                                 urnImagePreloader
                                 [preloadingImage]="hotelCard.image"
                                 [failedImage]="failedImage"
                                 [loadingImage]="''"
                                 (imageLoaded)="handleImageLoaded()"
                            >
                            </div>
                        </div>
                        <div class="rating-content" *ngIf="ratingAvailable">
                            <urn-pie-chart [value]="hotelCard.rating"></urn-pie-chart>
                        </div>
                    </div>
                    <div class="rating-container" *ngIf="ratingAvailable">
                        <!--urn-trustyou-seal [ratingPercentage]="hotelCard.rating"></urn-trustyou-seal-->
                    </div>
                    <div class="options-content">
                        <div class="option" *ngFor="let option of hotelCard.options">
                            <span class="urn-hotel-card-icon urn-hotel-card-icon-checked"></span>
                            <span class="option-text">{{option | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hotel-info-button-bar">
                <div class="hotel-info-buttons">
                    <div class="leaflet-icon-container">
                        <div class="leaflet-icon"
                             [ngClass]="{'like': hotelCard.liked, 'dislike': !hotelCard.liked}"
                             (click)="likeButtonClick()"
                        >
                            <span class="urn-hotel-card-icon"
                                  [ngClass]="{
                                      'urn-hotel-card-icon-like-unchecked': !hotelCard.liked,
                                      'urn-hotel-card-icon-like-checked': hotelCard.liked
                                  }"
                            >
                            </span>
                        </div>
                    </div>
                    <div class="hotel-info-button hotel-info" [class.active]="isHotelInfoOpened" (click)="switchHotelInfo()">
                        <div class="urn-hotel-card-icon urn-hotel-card-icon-info"></div>
                        <label class="button-text">{{'Hotelinfos' | translate}}</label>
                        <div *ngIf="isHotelInfoOpened" class="close-button">
                            <div class="urn-hotel-card-icon urn-hotel-card-icon-close"></div>
                        </div>
                    </div>

                    <div *ngIf="mapAvailable"
                         class="hotel-info-button map"
                         [class.active]="isHotelMapOpened"
                         (click)="switchHotelMap()">
                        <div class="urn-hotel-card-icon urn-hotel-card-icon-map"></div>
                        <label class="button-text">{{'In Karte' | translate}}</label>
                        <div *ngIf="isHotelMapOpened" class="close-button">
                            <div class="urn-hotel-card-icon urn-hotel-card-icon-close"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <urn-hotelview
            [hotelCard]="hotelCard"
            [isHotelViewOpened]="isHotelInfoOpened"
            [currentOperatorId]="hotelCard.tourOperator"
            [hotelviewConfiguration]="hotelviewConfiguration"
            [@hotelviewState]="isHotelInfoOpened ? 'visible' : 'hidden'"
        >
        </urn-hotelview>
        <div class="map-wrapper" [class.hotel-map-open]="isHotelMapOpened">
            <urn-map [hotelCards]="hotelCardAsArray" *ngIf="isHotelMapOpened"></urn-map>
        </div>
    </section>
    <footer class="submit-container">
        <div class="ticket-border-wrapper">
            <div class="ticket-border-first"></div>
            <div class="ticket-border-last"></div>
        </div>
        <div class="footer-content"></div>
    </footer>
</div>
