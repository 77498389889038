<div class="pagination-container">
    <div class="left-arrow-container" (click)="setCurrentPage(currentPage - 1)" [class.disabled]="currentPage === 1">
        <div class="arrow-container">
            <span class="urn-hotel-pagination-icon urn-hotel-pagination-icon-arrow-left"></span>
        </div>
        <div class="arrow-label">
            {{'Vorherige' | translate}}
        </div>
    </div>

    <div class="number-container">
        <ng-template [ngIf]="countOfPages <= 5">
            <span class="number" (click)="setCurrentPage(1)" [class.active]="currentPage === 1">1</span>
            <ng-template ngFor [ngForOf]="4 | generateArray" let-i = "index">
                <ng-template [ngIf]="countOfPages >= i + 2">
                    <span class="dot"></span>
                    <span class="number" (click)="setCurrentPage(i + 2)" [class.active]="currentPage === i + 2">{{i + 2}}</span>
                </ng-template>
            </ng-template>
        </ng-template>

        <ng-template [ngIf]="countOfPages > 5">
            <span class="number" (click)="setCurrentPage(1)" [class.active]="currentPage === 1">1</span>
            <ng-template [ngIf]="currentPage <= 3">
                <span class="dot"></span>
                <span class="number" (click)="setCurrentPage(2)"  [class.active]="currentPage === 2">2</span>
                <span class="dot"></span>
                <span class="number" (click)="setCurrentPage(3)"  [class.active]="currentPage === 3">3</span>
            </ng-template>
            <ng-template [ngIf]="currentPage === 3">
                <span class="dot"></span>
                <span class="number" (click)="setCurrentPage(4)">4</span>
            </ng-template>
            <span class="dots">...</span>
            <ng-template [ngIf]="currentPage > 3 && currentPage < countOfPages - 2">
                <span class="number" (click)="setCurrentPage(currentPage - 1)" >{{currentPage - 1}}</span>
                <span class="dot"></span>
                <span class="number active">{{currentPage}}</span>
                <span class="dot"></span>
                <span class="number" (click)="setCurrentPage(currentPage + 1)">{{currentPage + 1}}</span>
                <span class="dots">...</span>
            </ng-template>
            <ng-template [ngIf]="currentPage === countOfPages - 2">
                <span class="number" (click)="setCurrentPage(currentPage - 1)">{{currentPage - 1}}</span>
                <span class="dot"></span>
            </ng-template>
            <ng-template [ngIf]="currentPage >= countOfPages - 2">
                <span class="number" (click)="setCurrentPage(countOfPages - 2)" [class.active]="currentPage === countOfPages - 2">{{countOfPages - 2}}</span>
                <span class="dot"></span>
                <span class="number" (click)="setCurrentPage(countOfPages - 1)" [class.active]="currentPage === countOfPages - 1">{{countOfPages - 1}}</span>
                <span class="dot"></span>
            </ng-template>
            <span class="number" (click)="setCurrentPage(countOfPages)" [class.active]="currentPage === countOfPages">{{countOfPages}}</span>
        </ng-template>
    </div>
    <div class="right-arrow-container" (click)="setCurrentPage(currentPage + 1)" [class.disabled]="currentPage === countOfPages">
        <div class="arrow-label">
            {{'Die nächste' | translate}}
        </div>
        <div class="arrow-container">
            <span class="urn-hotel-pagination-icon urn-hotel-pagination-icon-arrow-right"></span>
        </div>
    </div>
</div>
