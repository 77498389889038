<div class="searchform" *ngIf="isVisible" [class.index-page]="isIndexPage">
    <header class="searchform-header">
        <h1 class="searchform-pointer-text">{{'Wohin geht Deine Reise?' | translate}}</h1>
    </header>

    <div class="searchform-container" [class.destination-extended]="hideDeparture">
        <div class="searchform-component" *ngIf="!hideDeparture">
            <urn-departure></urn-departure>
        </div>
        <div class="searchform-component">
            <urn-destination></urn-destination>
        </div>
        <div class="searchform-component">
            <urn-datepicker></urn-datepicker>
        </div>
        <div class="searchform-component">
            <urn-travellers-counter></urn-travellers-counter>
        </div>
    </div>
    <div class="filter-switch" *ngIf="isIndexPage" [class.active]="enableFilter">
        <div class="switch-container">
            <md-slide-toggle [(ngModel)]="enableFilter" (ngModelChange)="toggleFilter()">
                {{'Detailsuche' | translate}}
            </md-slide-toggle>
        </div>
    </div>
    <div class="searchform-filter-container"
         *ngIf="isIndexPage"
         [ngClass]="{'active-animation': animationIsActive}"
         #filterContainer
         [@filterVisible]="filterState"
         (@filterVisible.start)="onFilterAnimationStart(filterContainer)"
         (@filterVisible.done)="onFilterAnimationDone(filterContainer)"
    >
        <header class="searchform-filter-header">
            <div class="searchform-filter-border"></div>
            <div class="searchform-filter-label-wrapper">
                <span class="searchform-filter-label">{{'Detailsuche' | translate}}</span>
            </div>
        </header>
        <urn-filter
            (categoryChange)="setTag('cat', $event)"
            (hygieneChange)="setTag('hygiene', $event)"
            (transferChange)="setTag('gt14', $event)"
            (ratingChange)="setTag('rat', $event)"
            (priceChange)="setTag('prc', $event)"
            (boardChange)="setTag('gt06', $event)"
            (roomChange)="setTag('gt04', $event)">
        </urn-filter>
    </div>

    <div class="searchform-footer">
        <div class="searchform-footer-border"></div>
        <div class="searchform-button-container">
            <div class="searchform-button" (click)="submit()" tabindex="4">
                <strong *ngIf="isIndexPage">{{'Angebote suchen' | translate}}</strong>
                <strong *ngIf="!isIndexPage">{{'Aktualisieren' | translate}}</strong>
            </div>
        </div>
    </div>
</div>
