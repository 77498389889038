<div class="room-types-filter">
    <h3 class="title">{{'Zimmerart' | translate}}</h3>

    <div class="room-types">
        <urn-checkbox *ngFor="let roomType of roomTypes"
                      [(ngModel)]="roomType.selected"
                      [labelText]="translateRoomCode(roomType.code)"
                      (onChange)="setRoomType(roomType)">
        </urn-checkbox>
    </div>
</div>
