<div class="booking-page-card">
    <div class="salutation">
        <div class="salutation-icon icon urn-wizard-icon urn-wizard-icon-booking"></div>
        <p>
            {{'Vielen Dank für Ihre Reiseanmeldung' | translate}}.
            <br>
            {{'Sie erhalten in Kürze eine E-Mail mit einer Zusammenfassung der Reisedaten' | translate}}.
        </p>
    </div>
    <div class="button-wrapper">
        <div class="control-button close-button" (click)="close()">
            <strong>{{'Neue Suche starten' | translate}}</strong>
        </div>
    </div>
</div>
