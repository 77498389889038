<div class="thumbnails" [ngClass]="thumbnailsLayoutClasses">
    <ul>
        <li
            *ngFor="let image of previewImages; let i = index"
            class="preview-image"
            (click)="onItemClick(i)"
        >
            <figure [style.background-image]="'url(' + image['074'] + ')'">
                <img src="assets/images/aspect-ratio-placeholders/hotelview-media-thumbnail.png">
                <div class="overlay" *ngIf="showAdditionalImagesLabel(i)">
                    <label>{{additionalImagesLabelText | translate:additionalImagesCount}}</label>
                </div>
            </figure>
        </li>
    </ul>
</div>
