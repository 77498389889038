<div class="booking-description-card" [class.hotel-info-open]="isHotelInfoOpened">
    <section class="content">
        <div class="main-content">
            <div class="main-content-wrapper">
                <header class="header-container">
                    <div class="category-content">
                        <div class="category-item" *ngFor="let i of hotelCard.stars | generateArray"></div>
                    </div>
                    <h2 class="hotel-name-content">
                        <span class="hotel-name" title="{{hotelCard.title}}">{{hotelCard.title}}</span>
                    </h2>
                </header>

                <div class="hotel-info-container">
                    <div class="picture-container" [class.image-loaded]="imageLoaded">
                        <div class="picture-content">
                            <img src="./assets/images/aspect-ratio-placeholders/square.png">
                            <div class="picture-placeholder"></div>
                            <div class="picture-hotel"
                                 urnImagePreloader
                                 [preloadingImage]="hotelCard.image"
                                 [failedImage]="failedImage"
                                 [loadingImage]="''"
                                 (imageLoaded)="handleImageLoaded()"
                            >
                            </div>
                        </div>
                        <div class="rating-content" *ngIf="ratingAvailable">
                            <urn-pie-chart [value]="hotelCard.rating"></urn-pie-chart>
                        </div>
                    </div>
                    <div class="rating-container" *ngIf="ratingAvailable">
                        <urn-trustyou-seal [ratingPercentage]="hotelCard.rating"></urn-trustyou-seal>
                    </div>
                    <div class="general-travel-info">
                        <ul class="infos-list">
                            <li class="info-item destination">
                                <label>{{'Reiseziel' | translate}}:</label>
                                <span class="value">{{hotelCard.city}}</span>
                            </li>
                            <li class="info-item country">
                                <label>{{'Land' | translate}}:</label>
                                <span class="value">{{hotelCard.country | translate}}</span>
                            </li>
                            <li class="info-item tour-operator">
                                <urn-tour-operator-info-lightbox
                                    #opInfo
                                    [insolvencyInsurance]="validationModel?.prrl?.insolvencyInsurance"
                                    [legalIdentity]="validationModel?.prrl?.legalIdentity"
                                    [insolvencyInsuranceLiquidator]="validationModel?.prrl?.insolvencyInsuranceLiquidator"
                                ></urn-tour-operator-info-lightbox>

                                <label>{{'Veranstalter' | translate}}:</label>
                                <button (click)="opInfo.toggle()">
                                    <svg icon type="info"></svg>
                                </button>
                                <span class="value">{{tourOperator}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="hotel-info-button-bar">
                <div class="hotel-info-buttons">
                    <div class="hotel-info-button hotel-info" [class.active]="isHotelInfoOpened" (click)="switchHotelInfo()">
                        <div class="urn-hotel-card-icon urn-hotel-card-icon-info"></div>
                        <label class="button-text">{{'Hotelinfos' | translate}}</label>
                        <div *ngIf="isHotelInfoOpened" class="close-button">
                            <div class="urn-hotel-card-icon urn-hotel-card-icon-close"></div>
                        </div>
                    </div>

                    <div *ngIf="mapAvailable"
                         class="hotel-info-button map"
                         [class.active]="isHotelMapOpened"
                         (click)="switchHotelMap()">
                        <div class="urn-hotel-card-icon urn-hotel-card-icon-map"></div>
                        <label class="button-text">{{'In Karte' | translate}}</label>
                        <div *ngIf="isHotelMapOpened" class="close-button">
                            <div class="urn-hotel-card-icon urn-hotel-card-icon-close"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="travel-price-info-wrapper">
                <div class="travel-price-info">
                    <div class="touroperator-section-wrapper">
                        <div class="touroperator-section">
                            <div class="touroperator-logo-wrapper">
                                <div class="touroperator-logo"
                                     urnImagePreloader
                                     [preloadingImage]="touropImageUrl"
                                     [failedImage]="''"
                                     [loadingImage]="''"
                                ></div>
                            </div>
                            <div class="offer-available-info"
                                 [ngClass]="offerAvailableClass">{{offerAvailableLabel | translate}}</div>
                        </div>
                    </div>
                    <div class="price-section-wrapper">
                        <div class="price-section">
                            <label class="travellers-info">
                                <span class="adults" *ngIf="adultsCount">{{adultsCount}} {{adultsLabel | translate}}</span>
                                <span class="children" *ngIf="childrenCount"> + {{childrenCount}} {{childrenLabel | translate}}</span>
                            </label>
                            <div class="total-price-info">
                                <label class="total-price-value">{{ totalPrice | currency:'EUR':'symbol':'1.2-2'}}</label>
                                <label class="total-price-legend"
                                       title="{{'Gesamtpreis inkl. aller Zuschläge' | translate}}">
                                    {{'Gesamtpreis inkl. aller Zuschläge' | translate}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <urn-hotelview
            [hotelCard]="hotelCard"
            [isHotelViewOpened]="isHotelInfoOpened"
            [currentOperatorId]="hotelCard.tourOperator"
            [hotelviewConfiguration]="hotelviewConfiguration"
            [@hotelviewState]="isHotelInfoOpened ? 'visible' : 'hidden'"
        >
        </urn-hotelview>
        <div class="map-wrapper" [class.hotel-map-open]="isHotelMapOpened">
            <urn-map [hotelCards]="hotelCardAsArray" *ngIf="isHotelMapOpened"></urn-map>
        </div>
    </section>
    <footer class="submit-container">
        <div class="ticket-border-wrapper">
            <div class="ticket-border-first"></div>
            <div class="ticket-border-last"></div>
        </div>
        <div class="footer-content">
            <div class="travel-details">
                <header>
                    <h3>{{'Reisedaten' | translate}}</h3>
                </header>
                <div class="content">
                    <gosuite-list>
                        <li gosuite-list-item="bullet" class="dates">
                            <label>{{startDayOfWeek | translate}}, {{numericStartDate}} - {{endDayOfWeek | translate}}, {{numericEndDate}}</label>
                        </li>
                        <li gosuite-list-item="bullet" class="duration">
                            <label>{{duration}} {{'Tage' | translate}}</label>
                        </li>
                        <li gosuite-list-item="bullet" class="room">
                            <label>{{roomType}}</label>
                        </li>
                        <li gosuite-list-item="bullet" *ngIf="board" class="board">
                            <label>{{board | translate}}</label>
                        </li>
                        <li gosuite-list-item="bullet" class="transfer" *ngIf="transfer">
                            <label>{{transfer | translate}}</label>
                        </li>
                        <li gosuite-list-item="bullet">
                            <label *ngIf="!handicappedAccessible">{{'Nicht geeignet für Personen mit eingeschränkter Mobilität' | translate}}</label>
                            <label *ngIf="handicappedAccessible">{{'Geeignet für Personen mit eingeschränkter Mobilität' | translate}}</label>
                        </li>
                        <ng-container *ngIf="extraFeatures.length">
                            <li gosuite-list-item="bullet" class="extra" *ngFor="let extraFeature of extraFeatures;">
                                <label>{{extraFeature | translate}}</label>
                            </li>
                        </ng-container>
                    </gosuite-list>
                </div>
            </div>
            <div class="flight-routes-section" *ngIf="isFlightRoutesAvailable">
                <header class="flight-routes-header">
                    <h3>{{'Flugstrecke' | translate}}</h3>
                </header>
                <div class="flight-routes-content">
                    <ul class="flight-routes-list">
                        <li class="flight-route-item">
                            <urn-flight-route [flights]="validationModel.segments.out"
                                              [date]="offerData.startDate"></urn-flight-route>
                        </li>
                        <li class="flight-route-item">
                            <urn-flight-route [flights]="validationModel.segments.in"
                                              [date]="offerData.endDate"></urn-flight-route>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</div>
