<div
    [class.today]="isToday"
    [class.is-disabled]="disabled"
    [class.current]="isCurrent"
    [class.is-active]="isActive"
    [class.holiday]="isHoliday"
    class="calendar-day"
    (click)="select()">
    <span>{{day.date()}}</span>
</div>
