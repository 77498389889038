<header (click)="handleRegionHeaderClick()" [class.top-level-region]="isTopLevelRegion" [class.base-level-region]="!(region?.children?.length)">
    <i class="urn-region-icon" [ngClass]="{
        'urn-region-icon-chevron-down': region?.active,
        'urn-region-icon-chevron-right': !region?.active
    }"></i>
    <h4 class="region-title" [title]="region?.translation" (click)="handleRegionTitleClick()">{{ getRegionName() }}</h4>
    <span class="region-price">
        <span class="prefix">{{ 'ab' | translate }}</span>
        <span>{{ region?.price | currency:'EUR':'symbol':'1.2-2' }}</span>
    </span>
</header>

<ul
    class="child-regions"
    *ngIf="region?.children && !!(region?.children.length)"
    [class.expanded]="region?.active"
    [@childRegionListState]="region?.active ? 'expanded' : 'collapsed'"
>
    <li class="child-region" *ngFor="let childRegion of region.children">
        <urn-region-tree-item [region]="childRegion" (onSelectRegion)="onSelectRegion.emit($event)"></urn-region-tree-item>
    </li>
</ul>
