<div class="booking-error" *ngIf="!bookingProcessPossible && bookingFailed">
	<h2 translate>Entschuldigung!</h2>
	<p translate>Leider ist bei der Buchungsübermittlung ein Fehler aufgetreten.</p>
	<div class="button-wrapper">
		<button class="button cancel-button" (click)="cancelBooking()">{{'Zurück zur Angebotsauswahl' | translate}}</button>
	</div>
</div>

<div class="booking-process-possibility-message" *ngIf="!bookingProcessPossible && !bookingFailed && !showSubmitButtonPreloader">
	<urn-no-offers-message></urn-no-offers-message>
</div>

<div class="booking-validate-content" *ngIf="bookingProcessPossible">
	<h2>{{'Zusammenfassung' | translate}}</h2>
	<form [formGroup]="conditionForm">
		<div class="booking-description-card-wrapper" *ngIf="offer && offer.type !== 'NF'">
			<urn-booking-description-card [hotelCard]="hotelCard"></urn-booking-description-card>
		</div>

		<section class="booking-page-card offer-validation-card">
			<h3>{{'Nachricht des Veranstalters' | translate}}</h3>

			<div class="offer-validation-container">
				<div class="offer-validation-text">{{validation?.description || ''}}</div>
			</div>
		</section>

		<section class="booking-page-card customer">
			<h3>{{'Persönliche Angaben des Reiseanmelders' | translate}}</h3>

			<ul class="info-list">
				<li>
					<span>{{ 'Anrede' | translate }}:</span>
					<span>{{ customer.salutation }}</span>
				</li>
				<li>
					<span>{{ 'Titel' | translate }}:</span>
					<span>{{ customer.title }}</span>
				</li>
				<li>
					<span>{{ 'Vorname' | translate }}:</span>
					<span>{{ customer.name }}</span>
				</li>
				<li>
					<span>{{ 'Nachname' | translate }}:</span>
					<span>{{ customer.surname }}</span>
				</li>
				<li>
					<span>{{ 'Geburtsdatum' | translate }}:</span>
					<span>{{ customer.birthday }}</span>
				</li>
				<li>
					<span>{{ 'Straße' | translate }}:</span>
					<span>{{ customer.streetAndHouseNumber }}</span>
				</li>
				<li>
					<span>{{ 'PLZ' | translate }}:</span>
					<span>{{ customer.postalCode }}</span>
				</li>
				<li>
					<span>{{ 'Ort' | translate }}:</span>
					<span>{{ customer.city }}</span>
				</li>
				<li>
					<span>{{ 'Land' | translate }}:</span>
					<span>{{ customer.country }}</span>
				</li>
				<li>
					<span>{{ 'Telefon' | translate }}:</span>
					<span>{{ customer.phone }}</span>
				</li>
				<li>
					<span>{{ 'E-Mail' | translate }}:</span>
					<span>{{ customer.email }}</span>
				</li>
			</ul>
		</section>

		<section class="booking-page-card travellers" formArrayName="adultVisaInformationConditionsAccepted">
			<h3>{{'Angaben zu den Reisenden' | translate}}</h3>
			<ng-container
				*ngFor="let adultVisaForm of conditionForm.controls.adultVisaInformationConditionsAccepted.controls; let index=index"
				[formGroupName]="index"
			>
				<div class="traveller adult">
					<h4>{{index + 1 }}. {{'Reiseteilnehmer' | translate}}</h4>
					<ul class="info-list">
						<li>
							<span>{{ 'Anrede' | translate }}:</span>
							<span>{{ adultTravellers[index].salutation }}</span>
						</li>
						<li>
							<span>{{ 'Titel' | translate }}:</span>
							<span>{{ adultTravellers[index].title }}</span>
						</li>
						<li>
							<span>{{ 'Vorname' | translate }}:</span>
							<span>{{ adultTravellers[index].name }}</span>
						</li>
						<li>
							<span>{{ 'Nachname' | translate }}:</span>
							<span>{{ adultTravellers[index].surname }}</span>
						</li>
						<li>
							<span>{{ 'Geburtsdatum' | translate }}:</span>
							<span>{{ adultTravellers[index].birthday }}</span>
						</li>
						<li>
							<span>{{ 'Staatszugehörigkeit' | translate }}:</span>
							<span>{{ adultTravellers[index].nationality }}</span>
						</li>
						<li>
							<span>{{ 'Reisedokument' | translate }}:</span>
							<span>{{ getNameFromDocumentType(adultTravellers[index].identificationDocument) }}</span>
						</li>
						<li>
							<span>{{ 'Gültigkeit' | translate }}:</span>
							<span>{{ getDocumentValidityDescription(adultTravellers[index].identificationDocumentValidTo) }}</span>
						</li>
					</ul>

					<div class="visa-info">
						<h4>{{'Visa-Informationen' | translate}}</h4>

						<urn-visa-response
							*ngIf="adultVisaInformation[index]"
							[visaResponse]="adultVisaInformation[index]"
						></urn-visa-response>

						<urn-checkbox
							[formControl]="adultVisaForm.controls.accepted"
							[labelText]="'Ich habe die Pass- und Visa-Informationen gelesen und zur Kenntnis genommen' | translate"
						></urn-checkbox>

						<div class="error-message" *ngIf="adultVisaForm.controls.accepted.invalid" translate>
							booking-page-visa-warning-text
						</div>
					</div>
				</div>
				<div class="separator" *ngIf="index !== getAdultCount() - 1 || (index === getAdultCount() - 1 && getChildrenCount() !== 0)"></div>
			</ng-container>
			<ng-container
				*ngFor="let childrenVisaForm of conditionForm.controls.childrenVisaInformationConditionsAccepted.controls; let index=index"
				[formGroupName]="index"
			>
				<div class="traveller child">
					<h4>{{index + getAdultCount() + 1}}. {{'Reiseteilnehmer (Kind)' | translate}}</h4>
					<ul class="info-list">
						<li>
							<span>{{ 'Vorname' | translate }}:</span>
							<span>{{ childrenTravellers[index].name }}</span>
						</li>
						<li>
							<span>{{ 'Nachname' | translate }}:</span>
							<span>{{ childrenTravellers[index].surname }}</span>
						</li>
						<li>
							<span>{{ 'Geburtsdatum' | translate }}:</span>
							<span>{{ childrenTravellers[index].birthday }}</span>
						</li>
						<li>
							<span>{{ 'Staatszugehörigkeit' | translate }}:</span>
							<span>{{ childrenTravellers[index].nationality }}</span>
						</li>
						<li>
							<span>{{ 'Reisedokument' | translate }}:</span>
							<span>{{ getNameFromDocumentType(childrenTravellers[index].identificationDocument) }}</span>
						</li>
						<li>
							<span>{{ 'Gültigkeit' | translate }}:</span>
							<span>{{ getDocumentValidityDescription(childrenTravellers[index].identificationDocumentValidTo) }}</span>
						</li>
					</ul>

					<div class="visa-info">
						<urn-visa-response
							*ngIf="childrenVisaInformation[index]"
							[visaResponse]="childrenVisaInformation[index]"
						></urn-visa-response>

						<urn-checkbox
							[formControl]="childrenVisaForm.controls.accepted"
							[labelText]="'Ich habe die Pass- und Visa-Informationen gelesen und zur Kenntnis genommen' | translate"
						></urn-checkbox>

						<div class="error-message" *ngIf="childrenVisaForm.controls.accepted.invalid" translate>
							booking-page-visa-warning-text
						</div>
					</div>
					<div class="separator" *ngIf="index !== getChildrenCount() - 1"></div>
				</div>
			</ng-container>
		</section>

		<section class="booking-page-card remarks">
			<h3>{{'Unverbindliche Anmerkungen' | translate}}</h3>
			<p>{{ remarks || ('-- KEINE --' | translate) }}</p>

			<div class="separator"></div>

			<div class="data-checked-accepted">
				<urn-checkbox
					[formControl]="conditionForm.controls.bindingBookingAgreementAccepted"
					[labelText]="'booking-page-binding-booking-text' | translate"
				></urn-checkbox>
				<div class="error-message" *ngIf="conditionForm.controls.bindingBookingAgreementAccepted.invalid" translate>
					booking-page-binding-booking-warning-text
				</div>
			</div>
		</section>

		<section class="booking-page-card payment-modalities">
			<h3>{{'Zahlungsabwicklung' | translate}}</h3>

			<div class="content">
				<div class="agreement">
					<urn-checkbox
						[formControl]="conditionForm.controls.coordinationByPhone"
						[labelText]="'Abstimmung via Rückruf' | translate"
						[disabled]="true"
					></urn-checkbox>

					<div class="error-message" *ngIf="conditionForm.controls.coordinationByPhone.invalid" translate>
						booking-page-payment-warning-text
					</div>
				</div>
				<div class="description">
					<label>{{'Ich möchte die Zahlungsabwicklung über Telefon klären' | translate}}</label>
				</div>
			</div>
		</section>

		<section class="booking-page-card agb">
			<h3>{{'Geschäftsbedingungen (AGB)' | translate}}</h3>
			<div class="agb-link">
				<a [href]="agbLink" target="_blank" (click)="openAgbLink()" translate>AGB des Veranstalters</a>
			</div>
			<p translate>booking-page-agb-text</p>

			<div class="agreements-section">
				<div class="agreement">
					<urn-checkbox
						[formControl]="conditionForm.controls.generalTermsAndConditionsAccepted"
						[labelText]="'Ich bin damit einverstanden' | translate"
					></urn-checkbox>
					<div class="error-message" *ngIf="conditionForm.controls.generalTermsAndConditionsAccepted.invalid" translate>
						booking-page-agb-warning-text
					</div>
				</div>
			</div>
		</section>

		<section class="booking-page-card formpaper">
			<h3 *ngIf="isPackagedTour()">{{'Unterrichtung zum Formblatt Pauschalreise nach § 651a des Bürgerlichen Gesetzbuchs' | translate}}</h3>
			<h3 *ngIf="!isPackagedTour()">{{'Unterrichtung zum Formblatt Verbundene Reiseleistungen nach § 651w des Bürgerlichen Gesetzbuchs' | translate}}</h3>

			<div class="content">
				<urn-formpaper
					[prrlResponse]="validation?.prrl"
					[offer]="offer"
					[confirmControl]="conditionForm.controls.formpaperConditionsAccepted"
					(onFormPaperStoreSuccess)="handleFormPaperStoreSuccess($event)"
				></urn-formpaper>
			</div>

			<div class="agreements-section">
				<div class="agreement">
					<urn-checkbox
						[formControl]="conditionForm.controls.formpaperConditionsAccepted"
						[labelText]="'Ich habe das Formblatt gelesen und es zur Kenntnis genommen' | translate">
					</urn-checkbox>
					<div class="error-message" *ngIf="conditionForm.controls.formpaperConditionsAccepted.invalid" translate>
						booking-page-formpaper-warning-text
					</div>
				</div>
			</div>
		</section>

		<section class="booking-page-card summary">
			<h3>{{'Preisübersicht' | translate}}</h3>
			<div class="travellers">
				<div class="traveller adult" *ngFor="let adult of adultTravellers; let index=index">
					<label>{{ index + 1 }}. {{ 'Reiseteilnehmer' | translate }}</label>
					<div class="data">
						<span class="name">{{ adult.name }} {{ adult.surname }}</span>
						<span class="price" *ngIf="validation && validation.prices[index]">
							{{validation.prices[index].price | currency:'EUR':'symbol':'1.2-2'}}
						</span>
					</div>
				</div>
				<div class="traveller child" *ngFor="let children of childrenTravellers; let index=index">
					<label>{{ index + getAdultCount() + 1 }}. {{'Reiseteilnehmer (Kind)' | translate}}</label>
					<div class="data">
						<span class="name">{{ children.name }} {{ children.surname }}</span>
						<span class="price" *ngIf="validation && validation.prices[index + getAdultCount()]">
								{{validation.prices[index + getAdultCount()].price | currency:'EUR':'symbol':'1.2-2'}}
						</span>
					</div>
				</div>
			</div>
			<div class="total-price">
				<div class="total-price-wrapper">
					<span class="price">{{ validation.totalPrice | currency:'EUR':'symbol':'1.2-2'}}
					</span>
					<div class="remark">{{'Gesamtpreis inkl. aller Zuschläge' | translate}}</div>
				</div>
			</div>

			<div class="due-date" *ngIf="validation?.details?.deposit?.amount && validation?.details?.deposit['due-date']">
				<h3 translate>Fälligkeit</h3>
				<p>
					<span [innerHTML]="getDepositText(validation.details.deposit.amount, validation.details.deposit['due-date'])"></span>
					<!--span [innerHTML]="getDueDateText(validation.details.deposit.amount, validation.details.deposit['due-date'])"></span-->
				</p>
			</div>

			<div class="error-message" *ngIf="forceValidation && conditionForm.invalid">
				{{'Bitte überprüfen Sie Ihre Angaben!' | translate}}
			</div>

			<div class="button-bar">
				<div class="buttons">
					<button class="button cancel-button" (click)="cancel()">{{'Zurück' | translate}}</button>
					<urn-preloader-button
						(click)="submit()"
						[value]="getSubmitButtonLabel()"
						[showPreloader]="showSubmitButtonPreloader"
						[disabled]="conditionForm.invalid"
					></urn-preloader-button>
				</div>
			</div>
		</section>
	</form>
</div>
