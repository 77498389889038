<div class="select-container">
    <div class="button-container" (click)="toggleDropdown()">
        <div class="icon-wrapper">
            <i class="ura-select-icon ura-select-icon-arrow-down"></i>
        </div>
        <div class="placeholder-wrapper">
            <span class="placeholder">{{placeholderText | translate}}</span>
        </div>
    </div>
    <div class="options-container" *ngIf="isOpen">
        <div class="option"
             *ngFor="let option of options"
             [class.active]="option.value === selectedOption.value"
             (click)="selectOption(option)">
            <span>{{option.name | translate}}</span>
        </div>
    </div>
</div>
