<div class="leaflet-filter">
    <div class="filter-line" *ngFor="let option of options">
        <div class="name-container">
            <urn-checkbox
                [labelText]="option.name"
                [(ngModel)]="option.selected"
                (onChange)="changeOption()">
            </urn-checkbox>
        </div>

        <div class="items-container">
            <div class="item" *ngFor="let item of items" [style.width]="itemWidth">
                <div class="checkbox-container">
                    <div class="icon-container" [class.checked]="hasOption(option, item)">
                        <i class="urn-leaflet-filters urn-leaflet-filters-close"></i>
                        <i class="urn-leaflet-filters urn-leaflet-filters-checked"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
