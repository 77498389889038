<div class="rating-filter">
    <h3 class="title">
        <span>{{'Min. Bewertung' | translate}}:</span>
        <span *ngIf="value >= min_available" class="rating-value">{{value}}%</span>
        <span *ngIf="value < min_available" class="rating-value">{{'Beliebig' | translate}}</span>
    </h3>
    <urn-range-slider
        [(ngModel)]="value"
        [min]="min"
        [max]="max"
        [step]="step"
        (ngModelChange)="setRating()">
    </urn-range-slider>
</div>
