<div class="legal-notes-lightbox-container" *ngIf="isOpen">
    <div class="legal-notes-lightbox-header">
        {{'Veranstalter Informationen' | translate}}
        <div class="legal-notes-lightbox-close" (click)="close()">
            <i class="ura-impressum-icon ura-impressum-icon-close"></i>
        </div>
    </div>
    <div class="legal-notes-lightbox-body">
        <h4>{{ 'Rechtliche Veranstalteridentität' | translate }}</h4>
        <p *ngIf="legalIdentity?.length">
            {{ legalIdentity }}
        </p>
        <p *ngIf="!legalIdentity || legalIdentity.length <= 0">{{ 'Keine Informationen verfügbar' | translate }}</p>

        <hr>
        <h4>{{ 'Insolvenzversicherer' | translate }}</h4>

        <p *ngIf="insolvencyInsurance?.name">{{insolvencyInsurance.name}}</p>
        <p *ngIf="insolvencyInsurance?.address">{{insolvencyInsurance.address}}</p>
        <p *ngIf="insolvencyInsurance?.zipcode && insolvencyInsurance?.country && insolvencyInsurance?.city">
            {{insolvencyInsurance.country}} {{insolvencyInsurance.zipcode}}, {{insolvencyInsurance.city}}
        </p>
        <p *ngIf="insolvencyInsurance?.phone">
            {{'Telefon' | translate}}: {{insolvencyInsurance.phone}}
        </p>
        <p *ngIf="insolvencyInsurance?.mail">
            {{'E-Mail' | translate}}:
            <a [href]="'mailto::' + insolvencyInsurance.mail">{{insolvencyInsurance.mail}}</a>
        </p>
        <p *ngIf="isEmptyAddress(insolvencyInsurance)">{{ 'Keine Informationen verfügbar' | translate }}</p>

        <hr>
        <h4>{{ 'Insolvenzabwickler' | translate }}</h4>

        <p *ngIf="insolvencyInsuranceLiquidator?.name">{{insolvencyInsuranceLiquidator.name}}</p>
        <p *ngIf="insolvencyInsuranceLiquidator?.address">{{insolvencyInsuranceLiquidator.address}}</p>
        <p *ngIf="insolvencyInsuranceLiquidator?.zipcode && insolvencyInsuranceLiquidator?.country && insolvencyInsuranceLiquidator?.city">
            {{insolvencyInsurance.country}} {{insolvencyInsuranceLiquidator.zipcode}}, {{insolvencyInsuranceLiquidator.country}}
        </p>
        <p *ngIf="insolvencyInsuranceLiquidator?.phone">
            {{'Telefon' | translate}}: {{insolvencyInsuranceLiquidator.phone}}
        </p>
        <p *ngIf="insolvencyInsuranceLiquidator?.mail">
            {{'E-Mail' | translate}}:
            <a [href]="'mailto::' + insolvencyInsuranceLiquidator.mail">{{insolvencyInsuranceLiquidator.mail}}</a>
        </p>
        <p *ngIf="isEmptyAddress(insolvencyInsuranceLiquidator)">{{ 'Keine Informationen verfügbar' | translate }}</p>
    </div>
</div>
