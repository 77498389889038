<ng-container *ngIf="images != null && images.length > 0">
    <urn-hotelview-tab-content-description-mini-gallery
        [images]="images"
        [hotelviewConfiguration]="hotelviewConfiguration"
        (imageClick)="this.imageClick.emit($event)"
    ></urn-hotelview-tab-content-description-mini-gallery>
</ng-container>

<ng-container *ngIf="hotel">
    <div class="hotel-description" [innerHTML]="hotel._description"></div>
</ng-container>

<ng-container *ngIf="facts">
    <dl>
        <ng-container *ngFor="let factCategory of factsCategories">
            <dt>{{factCategory | translate}}</dt>
            <dd>
                <ul>
                    <li *ngFor="let fact of facts[factCategory]">
                        <i class="urn-hotelview-description-icon urn-hotelview-description-icon-done"></i><span>{{fact.value}}</span>
                    </li>
                </ul>
            </dd>
        </ng-container>
    </dl>
</ng-container>





