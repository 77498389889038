<div class="not-found--container">
    <div class="not-found--message">
        <div class="not-found--number">404</div>
        <div class="not-found--text">
            <div class="not-found--title">{{'Seite nicht gefunden.' | translate}}</div>
            <div class="not-found--description">
                {{'Entschuldigung' | translate}}! <br />
                {{'Diese Seite scheint nicht zu existieren' | translate}}
            </div>
            <div class="not-found--button-container" *ngIf="isAuthorized">
                <div class="not-found--button" (click)="goStartPage()">{{'Startseite' | translate}}</div>
            </div>
        </div>
    </div>
    <div class="not-found--slider" *ngIf="isAuthorized">
        <div *ngIf="initOfferSlider">
            <urn-offer-slider [offers]="offers"></urn-offer-slider>
        </div>
        <div *ngIf="!initOfferSlider">
            <urn-preload-spinner></urn-preload-spinner>
        </div>
    </div>
</div>
