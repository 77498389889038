<section class="input" (click)="handleFocus()">
    <div class="destination-icon-container">
        <i [hidden]="isOpen" class="urn-destination-icon urn-destination-icon-pin"></i>
        <i [hidden]="!isOpen" class="urn-destination-icon urn-destination-icon-search"></i>
    </div>
    <div class="input-container">
        <input
            tabindex="0"
            [(ngModel)]="searchText"
            [placeholder]="(placeholder$ | async) | translate"
            [disabled]="isDisabled"
            (focus)="handleFocus($event)"
            (blur)="handleBlur($event)"
            (ngModelChange)="handleSearchTextChange($event)"
        />
    </div>
    <div class="clear-icon-container" (click)="reset()">
        <i [hidden]="!model && !searchText?.length" class="urn-destination-icon urn-destination-icon-close"></i>
    </div>
</section>

<section class="dropdown" *ngIf="isOpen">
    <div class="results-container" *ngIf="!isLoading && (regions?.length || cities?.length || hotels?.length || airports?.length)">
        <section class="regions" *ngIf="regions.length">
            <header><span>Regionen</span></header>
            <ul class="result-list">
                <li *ngFor="let region of regions" (click)="selectDestination(region.id)">
                    <span>{{ region.name }}</span>
                </li>
            </ul>
        </section>

        <section class="cities" *ngIf="cities?.length">
            <header><span>Städte</span></header>
            <ul class="result-list">
                <li *ngFor="let city of cities" (click)="selectDestination(city.id)">
                    <span>{{ city.name }}</span>
                </li>
            </ul>
        </section>

        <section class="hotels" *ngIf="hotels?.length">
            <header><span>Hotels</span></header>
            <ul class="result-list">
                <li *ngFor="let hotel of hotels" (click)="selectDestination(hotel.id)">
                    <span>{{ hotel.name }}</span>
                </li>
            </ul>
        </section>

        <section class="airports" *ngIf="airports?.length">
            <header><span>Flughäfen</span></header>
            <ul class="result-list">
                <li *ngFor="let airport of airports" (click)="selectDestination(airport.id)">
                    <span>{{ airport.name }}</span>
                </li>
            </ul>
        </section>
    </div>

    <div class="loader-container" *ngIf="isLoading">
        <urn-preload-spinner [butterfly]="true"></urn-preload-spinner>
    </div>

    <div class="description-container" *ngIf="!isLoading">
        <p *ngIf="!searchText?.length">Gib einen Suchbegriff ein</p>
        <p *ngIf="searchText?.length > 0 && searchText?.length < 3">Bitte gebe einen Suchbegriff mit mindestens 3 Zeichen ein</p>
        <p *ngIf="searchText?.length >= 3 && !regions?.length && !cities?.length && !hotels?.length && !airports?.length">Leider erzielte deine Suche keine Treffer</p>
    </div>
</section>
