<div class="hotelview">
    <div class="content">
        <urn-tour-operator-selection
            *ngIf="enableTouropSelect"
            [currentOperatorId]="currentOperatorId"
            [tourOperatorSelectOptions]="tourOperatorSelectOptions"
            [layout]="hotelviewConfiguration.tourOperatorSelectionLayout"
            (tourOperatorChange)="onTourOperatorSelectChange($event)">
        </urn-tour-operator-selection>

        <div class="hotelview-tabs-wrapper">
            <urn-hotelview-tabs *ngIf="isLoaded"
                [hotelviewConfiguration]="hotelviewConfiguration">
                <urn-hotelview-tab [label]="'Beschreibung' | translate" [available]="isDescriptionTabAvailable">
                    <urn-hotelview-tab-content-description
                        [hotel]="hotelviewInfos.hotel"
                        [facts]="hotelviewInfos.facts"
                        [images]="hotelviewInfos.media.images"
                        [hotelviewConfiguration]="hotelviewConfiguration"
                        (imageClick)="onDescriptionImageClick($event)"
                    ></urn-hotelview-tab-content-description>
                </urn-hotelview-tab>
                <urn-hotelview-tab [label]="'Media' | translate" [available]="isMediaTabAvailable">
                    <urn-hotelview-tab-content-media
                        #mediatab
                        [images]="hotelviewInfos.media.images"
                        [video]="hotelviewInfos.media.video"
                        [hotelviewConfiguration]="hotelviewConfiguration"
                    ></urn-hotelview-tab-content-media>
                </urn-hotelview-tab>
                <urn-hotelview-tab [label]="'Bewertungen' | translate" [available]="isRatingTabAvailable">
                    <urn-hotelview-tab-content-rating
                        [ratings]="hotelviewInfos.rating"
                    ></urn-hotelview-tab-content-rating>
                </urn-hotelview-tab>
                <urn-hotelview-tab [label]="'Wetter' | translate" [available]="isClimateTabAvailable">
                    <urn-hotelview-tab-content-climate
                        [climate]="hotelviewInfos.climate"
                    ></urn-hotelview-tab-content-climate>
                </urn-hotelview-tab>
            </urn-hotelview-tabs>
            <div class="overlay" *ngIf="!isLoaded && expandingAnimationComplete">
                <urn-preload-spinner></urn-preload-spinner>
            </div>
        </div>
    </div>
</div>
