<div class="price-filter">
    <h3 class="title">
        <span>{{'Max. Preis' | translate}}:</span>
        <span *ngIf="maxPrice >= min_available" class="price-value">{{maxPrice}}€</span>
        <span *ngIf="maxPrice < min_available" class="price-value">{{'Beliebig' | translate}}</span>
    </h3>

    <urn-range-slider
        [(ngModel)]="maxPrice"
        [min]="min"
        [max]="max"
        [step]="step">
    </urn-range-slider>
</div>
