<nav class="navigation">
    <div class="navigation-button" (click)="toggleMenu()">
        <span class="navigation-icon navigation-icon-menu" [@openIconVisible]="navigationButtonState"></span>
        <span class="navigation-icon navigation-icon-close" [@closeIconVisible]="navigationButtonState"></span>
    </div>
    <div class="navigation-menu">
        <div class="navigation-label-wrapper" [class.hidden]="navigationItemsVisible">
            <div class="navigation-label"
                 [class.opened]="navigationItemsVisible"
                 (click)="labelClick()"
            >
                {{getActiveMenuItemLabel() | translate}}
            </div>
            <div class="navigation-label-overlay"
                 [@navigationLabelVisible]="navigationLabelState"
                 (@navigationLabelVisible.done)="handleNavigationLabelVisibleDone($event)">
            </div>
        </div>
        <div class="navigation-items-wrapper" [hidden]="!navigationItemsVisible">
            <ul class="navigation-items">
                <li *ngFor="let menuItem of getMenuItems()" class="navigation-item">
                    <a [ngClass]="{active: menuItem.active}" (click)="menuItemClick(menuItem)">
                        {{menuItem.name | translate}}
                    </a>
                </li>
            </ul>
            <div class="navigation-items-overlay"
                 [@navigationItemsVisible]="navigationItemsState"
                 (@navigationItemsVisible.done)="handleNavigationItemsVisibleDone($event)">
            </div>
        </div>
    </div>

    <div class="mobile-navigation-items-wrapper" [@mobileNavigationItemsVisible]="mobileNaviItemsState">
        <ul class="navigation-items">
            <li *ngFor="let menuItem of getMenuItems()" class="navigation-item">
                <a [ngClass]="{active: menuItem.active}" (click)="menuItemClick(menuItem)">
                    {{menuItem.name | translate}}
                </a>
            </li>
        </ul>
    </div>
</nav>
