<div class="active-video-wrapper" *ngIf="activeVideo">
    <figure>
        <img src="assets/images/aspect-ratio-placeholders/hotelview-media-active-image.png">
        <iframe [src]="activeVideo">{{activeVideo}}</iframe>
    </figure>
</div>

<div class="active-image-wrapper" *ngIf="activeImage">
    <figure [style.background-image]="'url(' + activeImage['800'] + ')'">
        <img src="assets/images/aspect-ratio-placeholders/hotelview-media-active-image.png">
        <figcaption>{{activeImage.type}}</figcaption>
    </figure>
</div>

<div class="thumbnails" [ngClass]="thumbnailsLayoutClasses">
    <ul>
        <li *ngIf="video != null && video.videoPlayerUrl != null"
            (click)="setActiveVideo(video)"
            [class.active]="activeVideo != null && activeImage == null"
        >
            <figure [style.background-image]="'url(' + video.previews[0].url + ')'">
                <img src="assets/images/aspect-ratio-placeholders/hotelview-media-thumbnail.png">
            </figure>
        </li>
        <ng-template [ngIf]="images != null && images.length > 0">
            <li *ngFor="let image of images"
                (click)="setActiveImage(image)"
                [class.active]="activeImage == image"
            >
                <figure [style.background-image]="'url(' + image['074'] + ')'">
                    <img src="assets/images/aspect-ratio-placeholders/hotelview-media-thumbnail.png">
                </figure>
            </li>
        </ng-template>
    </ul>
</div>
