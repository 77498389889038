<div class="travellers-counter"
     [class.dropdown-visible]="dropdownIsOpen"
     (focus)="showDropdown()"
     (blur)="onBlur($event)"
     tabindex="3"
>
    <div class="travellers-counter--wrapper" (mousedown)="showDropdown(); isFirstComponentInteraction()" (touchstart)="showDropdown(); isFirstComponentInteraction()">
        <div class="icon-person-container">
            <div class="urn-travellers-counter-icon urn-travellers-counter-icon-person"></div>
        </div>
        <div class="adults-selector" [hidden]="!firstComponentInteraction">
            <div class="icons-container">
                <div class="icon-container icon-minus-container" (click)="travellers.removeAdult()">
                    <i class="urn-travellers-counter-icon urn-travellers-counter-icon-remove"></i>
                </div>
                <div class="icon-container icon-plus-container" (click)="travellers.addAdult()">
                    <i class="urn-travellers-counter-icon urn-travellers-counter-icon-add"></i>
                </div>
            </div>
            <div class="travellers-counter--text" [class.focus]="dropdownIsOpen">
                {{travellers.adultCount}}
                {{travellers.adultCount === 1 ? ('Erwachsener' | translate) : ('Erwachsene' | translate)}} -
                {{travellers.childCount}}
                {{travellers.childCount === 1 ? ('Kind' | translate) : ('Kinder' | translate)}}
            </div>
        </div>
        <div class="component-placeholder" [hidden]="firstComponentInteraction">
            {{'Reiseteilnehmer' | translate}}
        </div>
    </div>
    <div class="travellers-counter--dropdown"
         [hidden]="!dropdownIsOpen"
         [class.children-selected]="travellers.childCount > 0"
    >
        <div class="travellers-counter--wrapper">
            <div class="icon-child-container">
                <i class="urn-travellers-counter-icon urn-travellers-counter-icon-child"></i>
            </div>
            <div class="adults-selector">
                <div class="icons-container">
                    <div class="icon-container icon-minus-container"
                         (click)="travellers.removeChild(travellers.childCount - 1)">
                        <i class="urn-travellers-counter-icon urn-travellers-counter-icon-remove"></i>
                    </div>
                    <div class="icon-container icon-plus-container" (click)="travellers.addChild(0)">
                        <i class="urn-travellers-counter-icon urn-travellers-counter-icon-add"></i>
                    </div>
                </div>
                <div class="travellers-counter--text" [class.focus]="travellers.childCount > 0 && dropdownIsOpen">
                    {{travellers.childCount}}
                    {{travellers.childCount === 1 ? ('Kind' | translate) : ('Kinder' | translate)}}
                </div>
            </div>
        </div>
        <div class="child-ages--wrapper" [hidden]="!(travellers.childCount > 0)">
            <div class="child-age--container" *ngIf="travellers.getChildAge(0) >= 0">
                <div class="icon-close-container" (click)="travellers.removeChild(0)">
                    <i class="urn-travellers-counter-icon urn-travellers-counter-icon-close"></i>
                </div>
                <div class="child-age-text">
                    <span>{{travellers.childAges[0]}}</span>
                    <span>{{travellers.childAges[0] === 1 ? ('Jahr' | translate) : ('Jahre' | translate)}}</span>
                </div>
                <div class="slider--container">
                    <urn-range-slider [(ngModel)]="travellers.childAges[0]"
                        [min]="sliderRange.min"
                        [max]="sliderRange.max"
                        [step]="sliderRange.step">
                    </urn-range-slider>
                </div>
            </div>
            <div class="child-age--container" *ngIf="travellers.getChildAge(1) >= 0">
                <div class="icon-close-container" (click)="travellers.removeChild(1)">
                    <i class="urn-travellers-counter-icon urn-travellers-counter-icon-close"></i>
                </div>
                <div class="child-age-text">
                    <span>{{travellers.childAges[1]}}</span>
                    <span>{{travellers.childAges[1] === 1 ? ('Jahr' | translate) : ('Jahre' | translate)}}</span>
                </div>
                <div class="slider--container">
                    <urn-range-slider [(ngModel)]="travellers.childAges[1]"
                        [min]="sliderRange.min"
                        [max]="sliderRange.max"
                        [step]="sliderRange.step">
                    </urn-range-slider>
                </div>
            </div>
            <div class="child-age--container" *ngIf="travellers.getChildAge(2) >= 0">
                <div class="icon-close-container" (click)="travellers.removeChild(2)">
                    <i class="urn-travellers-counter-icon urn-travellers-counter-icon-close"></i>
                </div>
                <div class="child-age-text">
                    <span>{{travellers.childAges[2]}}</span>
                    <span>{{travellers.childAges[2] === 1 ? ('Jahr' | translate) : ('Jahre' | translate)}}</span>
                </div>
                <div class="slider--container">
                    <urn-range-slider [(ngModel)]="travellers.childAges[2]"
                        [min]="sliderRange.min"
                        [max]="sliderRange.max"
                        [step]="sliderRange.step">
                    </urn-range-slider>
                </div>
            </div>
            <div class="child-age--container" *ngIf="travellers.getChildAge(3) >= 0">
                <div class="icon-close-container" (click)="travellers.removeChild(3)">
                    <i class="urn-travellers-counter-icon urn-travellers-counter-icon-close"></i>
                </div>
                <div class="child-age-text">
                    <span>{{travellers.childAges[3]}}</span>
                    <span>{{travellers.childAges[3] === 1 ? ('Jahr' | translate) : ('Jahre' | translate)}}</span>
                </div>
                <div class="slider--container">
                    <urn-range-slider [(ngModel)]="travellers.childAges[3]"
                        [min]="sliderRange.min"
                        [max]="sliderRange.max"
                        [step]="sliderRange.step">
                    </urn-range-slider>
                </div>
            </div>
            <div class="child-age--container" *ngIf="travellers.getChildAge(4) >= 0">
                <div class="icon-close-container" (click)="travellers.removeChild(4)">
                    <i class="urn-travellers-counter-icon urn-travellers-counter-icon-close"></i>
                </div>
                <div class="child-age-text">
                    <span>{{travellers.childAges[4]}}</span>
                    <span>{{travellers.childAges[4] === 1 ? ('Jahr' | translate) : ('Jahre' | translate)}}</span>
                </div>
                <div class="slider--container">
                    <urn-range-slider [(ngModel)]="travellers.childAges[4]"
                        [min]="sliderRange.min"
                        [max]="sliderRange.max"
                        [step]="sliderRange.step">
                    </urn-range-slider>
                </div>
            </div>
        </div>
        <div class="submit-button" (click)="onSubmitButtonClick()">
            {{'Übernehmen' | translate}}
        </div>
    </div>
</div>
