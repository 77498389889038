<header>
    <h3>
        <label>{{'Gesamtbewertung von' | translate}}</label>
        <span [class]="'rating' + ' ' + getRatingClass(ratings?.summary?.score)">{{ratings?.summary?.score}}%</span>
    </h3>
    <label class="rating-conclusion">
        {{'Zusammenfassung von' | translate}}
        {{ratings?.summary?.reviewCount}}
        {{'Bewertungen' | translate}}
    </label>
</header>

<nav>
    <ul class="traveller-types">
        <li [class.active]="travellerType === TRAVELLER_TYPE_ALL" (click)="setTravellerType(TRAVELLER_TYPE_ALL)">
            <span>{{'Alle' | translate}}</span>
        </li>
        <li [class.active]="travellerType === tType"
            *ngFor="let tType of travellerTypes"
            (click)="setTravellerType(tType)">
            <span>{{'travellerType_' + tType | translate}}</span>
        </li>
    </ul>
</nav>

<section class="good-to-know" *ngIf="goodToKnow.length > 0">
    <h4>{{'Gut zu wissen' | translate}}</h4>
    <div class="good-to-know-content">
        <ul>
            <li *ngFor="let gtk of goodToKnow">
                <div class="rating-icon-container">
                    <i class="urn-hotelview-rating-icon urn-hotelview-rating-icon-done"></i>
                </div>
                <label>{{gtk | translate}}</label>
            </li>
        </ul>
    </div>
</section>

<section class="review-highlights" *ngIf="categories.length > 0">
    <h3>{{'Review Highlights' | translate}}</h3>
    <div class="review-highlights-content">
        <dl>
            <ng-container *ngFor="let category of categories">
                <dt>
                    <h4>{{category | translate}}</h4>
                    <div class="rating-bar" *ngIf="ratings.travellerTypes != null">
                        <div
                            [class]= "'rating-indicator' + ' ' + getRatingClassForCategory(category)"
                            [style.width.%]="getScoreForCategory(category)"
                            title="{{getScoreForCategory(category)}}%"
                        ></div>
                    </div>
                </dt>
                <dd>
                    <blockquote *ngIf="getQuoteForCategory(category).length > 0">
                        <span *ngFor="let quote of getQuoteForCategory(category)" [innerHTML]="quote"></span>
                    </blockquote>
                    <div class="category-content" *ngIf="getHighlightsForCategory(category).length > 0">
                        <ul>
                            <li *ngFor="let highlight of getHighlightsForCategory(category)">
                                <div class="rating-icon-container">
                                    <i class="urn-hotelview-rating-icon urn-hotelview-rating-icon-done"></i>
                                </div>
                                <label [innerHTML]="highlight"></label>
                            </li>
                        </ul>
                    </div>
                </dd>
            </ng-container>
        </dl>
    </div>
</section>
