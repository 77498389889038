<div class="pagination-container">
    <div
        class="left-arrow-container"
        (click)="setCurrentPage(currentPage - 1)"
        [class.disabled]="currentPage === 1">
        <span class="urn-hotel-pagination-icon urn-hotel-pagination-icon-arrow-left"></span>
    </div>

    <div class="page-number">{{currentPage}}/{{countOfPages}}</div>

    <div
        class="right-arrow-container"
        (click)="setCurrentPage(currentPage + 1)"
        [class.disabled]="currentPage === countOfPages">
        <span class="urn-hotel-pagination-icon urn-hotel-pagination-icon-arrow-right"></span>
    </div>
</div>
