<div class="tour-operator-slider" *ngIf="showSlider">
    <div class="slider-button slide-up"
         *ngIf="showSlideArrows"
         [class.disabled]="upSlidingDisabled"
         (mousedown)="onSliderButtonMouseDown($event)"
         (mouseup)="onSliderButtonMouseUp($event)"
    >
        <div class="ura-tour-operator-slider-icon ura-tour-operator-slider-icon-arrow-up"></div>
    </div>
    <div class="slide-container"
         [style.height.px]="sliderHeight"
         (swipedown)="onSwipeDown()"
         (swipeup)="onSwipeUp()"
    >
        <div class="slide-content" [class.slide-animation]="showSlideAnimation" [style.top.px]="slidePosition">
            <div class="operator"
                 *ngFor="let operator of availableTourOperators"
                 (click)="selectOperator(operator)"
                 [class.active]="operator === selectedTourOperator"
            >
                <urn-tour-operator-logo [operatorId]="operator"></urn-tour-operator-logo>
            </div>
        </div>
    </div>
    <div class="slider-button slide-down"
         *ngIf="showSlideArrows"
         [class.disabled]="downSlidingDisabled"
         (mousedown)="onSliderButtonMouseDown($event)"
         (mouseup)="onSliderButtonMouseUp($event)"
    >
        <div class="ura-tour-operator-slider-icon ura-tour-operator-slider-icon-arrow-down"></div>
    </div>
</div>
