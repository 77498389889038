<div class="input-container"
     [ngClass]="{
         active: isActive,
         filled: isFilled,
         error: !isValid,
         disabled: disabled
     }"
     (click)="focusHandler()">

    <label class="placeholder" *ngIf="floatingLabel?.length">{{floatingLabel}}</label>

    <input #inputReference
           [attr.type]="type"
           [attr.readonly]="disabled ? 'readonly' : null"
           [placeholder]="placeholder || ''"
           (focus)="focusHandler()"
           (blur)="blurHandler()"
           (change)="valueChange()"
           [(ngModel)]="model" />
</div>
<div *ngIf="showErrorMessage" class="error-message-wrapper">
    {{errorMessage}}
</div>
