<div class="city-select-box">
    <div class="placeholder-container" (click)="toggleFilter()">
        <div class="placeholder-icon">
            <i class="urn-hotel-page-icon"
                [ngClass]="{
                    'urn-hotel-page-icon-right': !showFilter,
                    'urn-hotel-page-icon-down': showFilter
                }">
            </i>
        </div>
        <span class="placeholder-title">
            {{getPlaceholder() | translate}}
        </span>
    </div>
    <div class="cities-list" [class.open]="showFilter">
        <div class="city-item" *ngIf="citySelected && regionAvailable" (click)="getBack()">{{'Alle Orte' | translate}}</div>
        <div class="city-item" *ngFor="let city of citiesList" (click)="selectCity(city)">
            <span>{{city.name}}</span>
        </div>
    </div>
</div>
