<div class="category-filter">
    <h3 class="title">{{title}}</h3>
    <div class="category-item-wrapper">
        <div class="category-item"
             *ngFor="let categorySelected of categorySelection; let index = index"
             [class.selected]="categorySelected"
             [class.striped]="index === 0"
             (click)="changeCategory(index)"
        ></div>
    </div>
    <div class="clear-button" *ngIf="!categorySelection[0]" (click)="changeCategory(0)">
        {{'Auswahl entfernen' | translate}}
    </div>
</div>
