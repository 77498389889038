<div class="select period" [class.is-chosen]="isChosen" [class.open]="isOpen" [class.disabled]="disabled">
    <div class="tooltip">
        <span>{{tooltip}}</span>
    </div>
    <div class="label" (click)="toggle()">
        <ng-content select="[urn-datepicker-select--no-value]" *ngIf="selected === null"></ng-content>
        <ng-content select="[urn-datepicker-select--value]" *ngIf="selected !== null"></ng-content>
    </div>
    <div class="options">
        <div *ngFor="let item of values;" [class.disabled]="item.enabled === false" (click)="selectItem(item)">
            {{item.text | translate}}
        </div>
    </div>
</div>
