<div class="validation-error" *ngIf="validationFailed">
    <h2 translate>Entschuldigung!</h2>
    <p translate>Leider ist das Angebot zwischenzeitlich nicht mehr verfügbar.</p>
    <div class="button-wrapper">
        <button class="cancel-button" (click)="cancel()">{{'Zurück zur Angebotsauswahl' | translate}}</button>
    </div>
</div>

<div class="booking-page-container" *ngIf="bookingProcessPossible">
    <h2>{{'Erfassung personenbezogener Daten' | translate}}</h2>

    <form [formGroup]="bookingForm" class="booking-form">
        <section class="booking-page-card customer" formGroupName="customer">
            <h3>{{'Persönliche Angaben des Reiseanmelders' | translate}}</h3>

            <p>{{'booking-page-customer-notice' | translate}}</p>

            <div class="form-row">
                <div class="form-element">
                    <urn-select
                        class="salutation"
                        [formControl]="bookingForm.controls['customer'].controls['salutation']"
                        [forceValidation]="forceValidation"
                        [placeholder]="'Anrede' | translate"
                        [options]="salutations"
                    ></urn-select>
                    <urn-input
                        class="title"
                        [formControl]="bookingForm.controls['customer'].controls['title']"
                        [forceValidation]="forceValidation"
                        [floatingLabel]="'Titel' | translate"
                    ></urn-input>
                </div>
                <div class="form-element">
                    <urn-input
                        [formControl]="bookingForm.controls['customer'].controls['name']"
                        [forceValidation]="forceValidation"
                        [floatingLabel]="'Vorname' | translate"
                    ></urn-input>
                </div>
                <div class="form-element">
                    <urn-input
                        [formControl]="bookingForm.controls['customer'].controls['surname']"
                        [forceValidation]="forceValidation"
                        [floatingLabel]="'Nachname' | translate"
                    ></urn-input>
                </div>
            </div>
            <div class="form-row">
                <div class="form-element">
                    <urn-input
                        [formControl]="bookingForm.controls['customer'].controls['birthday']"
                        [forceValidation]="forceValidation"
                        [floatingLabel]="'Geburtsdatum' | translate"
                        [placeholder]="'DD.MM.YYYY'"
                    ></urn-input>
                </div>
                <div class="form-element">
                    <urn-input
                        [formControl]="bookingForm.controls['customer'].controls['phone']"
                        type="tel"
                        [forceValidation]="forceValidation"
                        [floatingLabel]="'Telefon' | translate"
                    ></urn-input>
                </div>
                <div class="form-element">
                    <urn-input
                        [formControl]="bookingForm.controls['customer'].controls['email']"
                        type="email"
                        [forceValidation]="forceValidation"
                        [floatingLabel]="'E-Mail' | translate"
                    ></urn-input>
                </div>
            </div>
            <div class="form-row">
                <div class="form-element">
                    <urn-input
                        [formControl]="bookingForm.controls['customer'].controls['street']"
                        [forceValidation]="forceValidation"
                        [floatingLabel]="'Straße' | translate"
                    ></urn-input>
                </div>
                <div class="form-element">
                    <urn-input
                        class="zip-code"
                        [formControl]="bookingForm.controls['customer'].controls['zipCode']"
                        [forceValidation]="forceValidation"
                        [floatingLabel]="'PLZ' | translate"
                    ></urn-input>
                    <urn-input
                        class="city"
                        [formControl]="bookingForm.controls['customer'].controls['city']"
                        [forceValidation]="forceValidation"
                        [floatingLabel]="'Ort' | translate"
                    ></urn-input>
                </div>
                <div class="form-element">
                    <urn-select
                        [formControl]="bookingForm.controls['customer'].controls['country']"
                        [forceValidation]="forceValidation"
                        [placeholder]="'Land' | translate"
                        [options]="countries"
                    ></urn-select>
                </div>
            </div>
        </section>

        <section class="booking-page-card travellers">
            <h3>{{'Angaben zu den Reisenden' | translate}}</h3>

            <urn-checkbox
                [ngModel]="false"
                [ngModelOptions]="{standalone: true}"
                (onChange)="isCustomerPartOfTripChange($event)"
                [labelText]="'Ich nehme selbst an der Reise teil' | translate"
            ></urn-checkbox>

            <ng-container formArrayName="adultTravellers">
                <div
                    class="traveller adult"
                    *ngFor="let adult of bookingForm.controls.adultTravellers.controls; let index=index"
                    [formGroupName]="index"
                >
                    <h4>{{index + 1}}. {{'Reiseteilnehmer' | translate}}</h4>
                    <div class="form-row form-row-4">
                        <div class="form-element">
                            <urn-select
                                [formControl]="adult.controls['salutation']"
                                [disabled]="index == 0 && !(isCustomerNotPartOfTrip$ | async)"
                                [forceValidation]="forceValidation"
                                [placeholder]="'Anrede' | translate"
                                [options]="salutations"
                            ></urn-select>
                        </div>
                        <div class="form-element">
                            <urn-input
                                [formControl]="adult.controls['name']"
                                [disabled]="index == 0 && !(isCustomerNotPartOfTrip$ | async)"
                                [forceValidation]="forceValidation"
                                [floatingLabel]="'Vorname' | translate"
                            ></urn-input>
                        </div>
                        <div class="form-element">
                            <urn-input
                                [formControl]="adult.controls['surname']"
                                [disabled]="index == 0 && !(isCustomerNotPartOfTrip$ | async)"
                                [forceValidation]="forceValidation"
                                [floatingLabel]="'Nachname' | translate"
                            ></urn-input>
                        </div>
                        <div class="form-element">
                            <urn-input
                                [formControl]="adult.controls['birthday']"
                                [disabled]="index == 0 && !(isCustomerNotPartOfTrip$ | async)"
                                [forceValidation]="forceValidation"
                                [floatingLabel]="'Geburtsdatum' | translate"
                                [placeholder]="'DD.MM.YYYY'"
                            ></urn-input>
                        </div>
                    </div>
                    <div class="form-row form-row-4">
                        <div class="form-element">
                            <urn-select
                                [formControl]="adult.controls['country']"
                                [forceValidation]="forceValidation"
                                [placeholder]="'Staatszugehörigkeit' | translate"
                                [options]="countries"
                            ></urn-select>
                        </div>
                        <div class="form-element">
                            <urn-select
                                [formControl]="adult.controls['document']"
                                [forceValidation]="forceValidation"
                                [placeholder]="'Reisedokument (opt.)' | translate"
                                [options]="documentTypes"
                            ></urn-select>
                        </div>
                        <div class="form-element">
                            <urn-input
                                [formControl]="adult.controls['documentValidity']"
                                [forceValidation]="forceValidation"
                                [floatingLabel]="'Gültigkeit (opt.)' | translate"
                                [placeholder]="'DD.MM.YYYY'"
                            ></urn-input>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="separator" *ngIf="bookingForm?.controls?.childrenTravellers?.controls?.length"></div>

            <ng-container formArrayName="childrenTravellers">
                <div
                    class="traveller child"
                    *ngFor="let child of bookingForm.controls.childrenTravellers.controls; let index=index"
                    [formGroupName]="index"
                >
                    <h4>{{bookingForm.controls.adultTravellers.controls.length + index + 1}}. {{'Reiseteilnehmer (Kind)' | translate}}</h4>
                    <div class="form-row form-row-4">
                        <div class="form-element">
                            <urn-input
                                [formControl]="child.controls['name']"
                                [forceValidation]="forceValidation"
                                [floatingLabel]="'Vorname' | translate"
                            ></urn-input>
                        </div>
                        <div class="form-element">
                            <urn-input
                                [formControl]="child.controls['surname']"
                                [forceValidation]="forceValidation"
                                [floatingLabel]="'Nachname' | translate"
                            ></urn-input>
                        </div>
                        <div class="form-element">
                            <urn-input
                                [formControl]="child.controls['birthday']"
                                [forceValidation]="forceValidation"
                                [floatingLabel]="'Geburtsdatum' | translate"
                                [placeholder]="'DD.MM.YYYY'"
                            ></urn-input>
                        </div>
                    </div>
                    <div class="form-row form-row-4">
                        <div class="form-element">
                            <urn-select
                                [formControl]="child.controls['country']"
                                [forceValidation]="forceValidation"
                                [placeholder]="'Staatszugehörigkeit' | translate"
                                [options]="countries"
                            ></urn-select>
                        </div>
                        <div class="form-element">
                            <urn-select
                                [formControl]="child.controls['document']"
                                [forceValidation]="forceValidation"
                                [placeholder]="'Reisedokument' | translate"
                                [options]="documentTypes"
                            ></urn-select>
                        </div>
                        <div class="form-element">
                            <urn-input
                                [formControl]="child.controls['documentValidity']"
                                [forceValidation]="forceValidation"
                                [floatingLabel]="'Gültigkeit' | translate"
                                [placeholder]="'DD.MM.YYYY'"
                            ></urn-input>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="separator"></div>

            <div class="remarks">
                <urn-textarea
                    formControlName="remarks"
                    [placeholder]="'Unverbindliche Anmerkungen' | translate"
                ></urn-textarea>
            </div>
        </section>

        <section class="booking-page-card button-bar">
            <div class="buttons">
                <button class="cancel-button" (click)="cancel()">{{'Zurück' | translate}}</button>
                <urn-preloader-button
                    (click)="submit()"
                    [value]="'Daten übernehmen' | translate"
                    [disabled]="!bookingForm.valid"
                    [showPreloader]="showSubmitButtonPreloader"
                ></urn-preloader-button>
            </div>
        </section>
    </form>
</div>
