<div class="{{['content', pageName].join(' ')}}" urnIframeHelper="">
    <div class="main-menu">
        <div class="main-menu-wrapper">
            <div class="main-menu-icons-wrapper">
                <div class="leaflet-icon-wrapper" (click)="leafletClick()" *ngIf="isAuthorized">
                    <i class="app-navigation-icon app-navigation-icon-like"></i>
                    <span class="counter" *ngIf="leafletCount > 0">{{leafletCount}}</span>
                    <div class="leaflet-tooltip" [ngClass]="{active: showLeafletTooltip}">
                        <div class="leaflet-tooltip-content">
                            {{'Es sind noch keine Angebote im Merkzettel' | translate}}
                        </div>
                    </div>
                </div>
                <div class="language-wrapper">
                    <urn-language-popup></urn-language-popup>
                </div>
            </div>
            <div class="offer-type-wrapper" *ngIf="isAuthorized">
                <urn-navigation-menu></urn-navigation-menu>
            </div>
            <div class="button-back" *ngIf="buttonBackShow" (click)="buttonBackClick()">
                {{'Zurück' | translate}}
            </div>
        </div>
    </div>
    <div class="main-container">
        <div class="main-container-wrapper">
            <urn-searchform *ngIf="pageName !== 'leaflet' && isAuthorized"></urn-searchform>
            <urn-wizard *ngIf="pageName !== 'leaflet' && isAuthorized"></urn-wizard>
            <div class="page-wrapper">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
