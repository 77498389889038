<div class="wizard-wrapper" [class.hotel-item-disabled]="currentType === 'NF'">
    <urn-wizard-generator *ngIf="isVisible" [selectedIndex]="selectedIndex" (onSelectChange)="onWizardChange($event)">
        <urnWizardItem>
            <ng-template urnWizardLabel>
                <div class="wizard-item-content">
                    <span class="icon urn-wizard-icon urn-wizard-icon-search"></span>
                    <span class="text" translate>Suche</span>
                </div>
            </ng-template>
        </urnWizardItem>
        <urnWizardItem>
            <ng-template urnWizardLabel>
                <div class="wizard-item-content">
                    <span class="icon urn-wizard-icon  urn-wizard-icon-regions"></span>
                    <span class="text" translate>Regionen</span>
                </div>
            </ng-template>
        </urnWizardItem>
        <urnWizardItem *ngIf="currentType !== 'NF'">
            <ng-template urnWizardLabel>
                <div class="wizard-item-content">
                    <span class="icon urn-wizard-icon urn-wizard-icon-hotels"></span>
                    <span class="text" translate>Hotels</span>
                </div>
            </ng-template>
        </urnWizardItem>
        <urnWizardItem>
            <ng-template urnWizardLabel>
                <div class="wizard-item-content">
                    <span class="icon urn-wizard-icon urn-wizard-icon-offer"></span>
                    <span class="text" translate>Angebote</span>
                </div>
            </ng-template>
        </urnWizardItem>
        <urnWizardItem>
            <ng-template urnWizardLabel>
                <div class="wizard-item-content">
                    <span class="icon urn-wizard-icon urn-wizard-icon-booking"></span>
                    <span class="text" translate>Buchung</span>
                </div>
            </ng-template>
        </urnWizardItem>
    </urn-wizard-generator>
</div>
