<div class="carousel" [hidden]="hideSlider">
    <div class="special-offers">
        <div class="paginator-arrow paginator-arrow-left"
             (click)="slideBefore()"
             [hidden]="beforeArrowHidden">
            <div class="urn-wizard-icon urn-wizard-icon-navigate_before"></div>
        </div>
        <div class="paginator-arrow paginator-arrow-right"
             (click)="slideNext()"
             [hidden]="nextArrowHidden">
            <div class="urn-wizard-icon urn-wizard-icon-navigate_next"></div>
        </div>

        <div id="slide-element" class="special-offers--container" (swipeleft)="swipe($event.type)" (swiperight)="swipe($event.type)">
            <div *ngFor="let offer of offers"
                 class="special-offer"
                 (click)="openOfferLink(offer.link)">
                <img class="special-offer--image" [src]="offer.imageSrc">
                <div class="special-offer--text-overlay"></div>
                <div class="special-offer--text">{{offer.text | translate}}</div>
            </div>
        </div>
    </div>

    <div class="carousel-paginator" [hidden]="hidePagination">
        <div class="pagination-pointer"
             *ngFor="let ele of paginationElements"
             [ngClass]="{'selected': slidePosition === ele.slidePos}"
             (click)="slide(ele.slidePos)">
        </div>
    </div>
</div>
