<div class="recent-offers-container" *ngIf="recentOffers.length > 0">
    <div class="recent-offers-title">{{'Zuletzt betrachtet' | translate}}</div>
    <div class="recent-offers-list">
        <div class="recent-offer" *ngFor="let offer of recentOffers; let i = index;" (click)="submitRecent(i)">
            <div class="recent-offer-image">
                <img src="assets/images/aspect-ratio-placeholders/square.png">
                <div class="picture-hotel"
                     urnImagePreloader
                     [preloadingImage]="getOfferImage(offer.id)"
                     [failedImage]="failedImage"
                     [loadingImage]="loadingImage"
                ></div>
            </div>
            <div class="recent-offer-info">
                <div class="recent-offer-category">
                    <div class="category-item" *ngFor="let i of offer.score | generateArray"></div>
                </div>
                <div class="recent-offer-title">{{offer.name}}</div>
                <div class="recent-offer-price">{{'ab' | translate}} <span>{{offer.price}} &euro;</span></div>
            </div>
        </div>
    </div>
</div>
