<div class="textarea-container"
     [ngClass]="{
         active: isActive,
         filled: isFilled,
         error: !isValid
     }"
     (click)="focusHandler()">

    <label class="placeholder">{{placeholder}}</label>

    <textarea
        #textareaReference
        (focus)="focusHandler()"
        (blur)="blurHandler()"
        (keydown)="calculateHeight()"
        (keyup)="calculateHeight()"
        (change)="valueChange()"
        [(ngModel)]="model">
    </textarea>
</div>
