<div class="flight-route" [class.expanded]="flightRouteExpanded">
    <header class="flight-route-header">
        <h4>
            <span class="departure" *ngIf="airportOfDeparture">{{airportOfDeparture.departureCode | translate}} ({{airportOfDeparture.departureCode}})</span>
            <span class="arrival" *ngIf="destinationAirport">{{destinationAirport.destinationCode | translate}} ({{destinationAirport.destinationCode}})</span>
        </h4>
    </header>
    <div class="flight-route-content" [@flightRouteExpanded]="flightRouteState">
        <ul class="flights" *ngIf="hasFlightsData">
            <li *ngFor="let flight of flights; let i = index" class="flight">
                <ul>
                    <li *ngIf="i == 0" class="general-info">
                        <label>
                            {{getFlightDirectionLabel(flight.direction) | translate}}
                            :
                            {{('DAY_OF_WEEK_SHORT_' + (date.getDay() + 1)) | translate}},
                            {{dateWrapped.format('DD.MM.YYYY')}}
                            ({{flights[0].departureTime}} - {{flights[flights.length - 1].arrivalTime}}
                            {{'Uhr' | translate}})
                        </label>
                    </li>
                    <li class="airport-of-departure-info">
                        <label>
                            {{'Abflughafen' | translate}}:
                            {{flight.departureCode | translate}}
                            ({{flight.departureCode}})
                        </label>
                    </li>
                    <li class="destination-airport-info">
                        <label>
                            {{'Zielflughafen' | translate}}:
                            {{flight.destinationCode | translate}}
                            ({{flight.destinationCode}})
                        </label>
                    </li>
                    <li class="carrier-info">
                        <label>
                            {{'Fluggesellschaft' | translate}}:
                            {{flight.carrierCode | translate}}
                            ({{flight.carrierCode}})
                        </label>
                    </li>
                    <li class="flight-number-info">
                        <label>{{'Flugnummer' | translate}}: {{flight.flightId}}</label>
                    </li>
                    <li class="flight-class">
                        <label>{{'Flugklasse' | translate}}: {{'2. Klasse' | translate}}</label>
                    </li>
                    <li class="departure-time-info">
                        <label>{{'Abflugzeit' | translate}}: {{flight.departureTime}} {{'Uhr' | translate}}</label>
                    </li>
                    <li class="arrival-time-info">
                        <label>{{'Ankunftszeit' | translate}}: {{flight.arrivalTime}} {{'Uhr' | translate}}</label>
                    </li>
                    <li *ngIf="i != flights.length - 1" class="connecting-flight-info">
                        <label>
                            {{'Zwischenstopp' | translate}}
                            --
                            {{flight.destinationCode | translate}}
                            ({{flight.destinationCode}})
                            {{flight?.stopDuration || ''}}
                        </label>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
    <div class="toggle-flight-route-details-button"
         (click)="toggleFlightRouteState()">
        <label>{{flightRouteDetailsButtonLabel | translate}}</label>
    </div>
</div>
