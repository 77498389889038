<div class="datepicker" [class.active]="isOpen" (keydown)="onKeyDown($event)" (focus)="open()" tabindex="2">
    <div id="field" class="field displayed-values" (click)="open()">
        <i class="urn-datepicker-icon urn-datepicker-icon-calend"></i>
        <a class="remove" tabindex="-1" *ngIf="isOpen" (click)="clearAll()">
            <i class="urn-datepicker-icon urn-datepicker-icon-close"></i>
        </a>
        <div class="text">
            <div class="placeholder" *ngIf="isDefaultData">{{'Reisezeitraum und Dauer' | translate}}</div>
            <div class="value value--desktop" *ngIf="!isDefaultData">
                {{startDate.format('D')}}. {{'MONTH_' + (startDate.month() + 1) | translate}} -
                {{endDate.format('D')}}. {{'MONTH_' + (endDate.month() + 1) | translate}},
                {{selectedPeriod ? (selectedPeriod.text | translate) : ''}}
            </div>
            <div class="value value--mobile" *ngIf="!isDefaultData">
                {{startDate.format('D')}}.{{startDate.format('MM')}}. -
                {{endDate.format('D')}}.{{endDate.format('MM')}}.,
                {{selectedPeriod ? (selectedPeriod.text | translate) : ''}}
            </div>
        </div>
    </div>
    <div class="dropdown">
        <div class="urn-datepicker">
            <div class="header">
                <div class="titles">
                    <div [class.active]="mode === modeStart">{{'Früheste Hinreise' | translate}}</div>
                    <div [class.active]="mode === modeEnd">{{'Späteste Rückreise' | translate}}</div>
                </div>
                <div class="buttons">
                    <div [class.active]="mode === modeStart" [class.uppercase]="startDate">
                        {{startDate.format('DD.MM.YYYY')}}
                    </div>
                    <div [class.active]="mode === modeEnd" [class.uppercase]="endDate">
                        {{endDate.format('DD.MM.YYYY')}}
                    </div>
                </div>
            </div>
            <div class="calendar-cal-container"
                 (mouseleave)="startDate || endDate ? generateCalendar() : false">
                <div class="calendar-navigation">
                    <div class="calendar-prev" (click)="prevMonth()">
                        <i class="urn-datepicker-icon urn-datepicker-icon-left"></i>
                    </div>
                    <div class="calendar-next" (click)="nextMonth()">
                        <i class="urn-datepicker-icon urn-datepicker-icon-right"></i>
                    </div>
                </div>
                <div class="calendar-cal-top">
                    <span>
                        {{'MONTH_' + (baseDate.month() + 1) | translate}}
                        {{baseDate.year()}}
                    </span>
                </div>
                <div class="calendar-day-names">
                    <span>{{'Mo' | translate}}</span>
                    <span>{{'Di' | translate}}</span>
                    <span>{{'Mi' | translate}}</span>
                    <span>{{'Do' | translate}}</span>
                    <span>{{'Fr' | translate}}</span>
                    <span>{{'Sa' | translate}}</span>
                    <span>{{'So' | translate}}</span>
                </div>
                <div class="calendar-days">
                    <urn-calendar-day
                        *ngFor="let day of days; let i = index;"
                        [day]="day"
                        [selectedStartDate]="startDate"
                        [selectedEndDate]="endDate"
                        [selectedMonth]="baseDate"
                        [hoveredDate]="hoveredDate"
                        [mode]="mode"
                        [selectedHoliday]="selectedHoliday"
                        (onSelect)="selectDate($event)"
                        (onHover)="hoverDate($event)"
                    ></urn-calendar-day>
                </div>
            </div>
            <!-- TODO: add this code once we have our own api -->
            <!--urn-datepicker-select
                *ngIf="allowShowHolidaysSelector"
                [values]="holidayCountries"
                [tooltip]="'Ferien anzeigen'"
                (onSelect)="selectHoliday($event)"
                [isChosen]="selectedHoliday && selectedHoliday.year !== null"
                class="holidays">
                <div urn-datepicker-select--no-value><i class="urn-datepicker-icon urn-datepicker-icon-calend"></i> {{'Ferien anzeigen' | translate}}</div>
                <div urn-datepicker-select--value><i class="urn-datepicker-icon urn-datepicker-icon-calend"></i> {{'Ferien' | translate}}: {{selectedHoliday ? selectedHoliday.title : ''}}</div>
            </urn-datepicker-select-->

            <urn-datepicker-select
                [values]="periodRanges"
                [tooltip]="'Bitte wählen Sie zuerst einen Reisezeitraum'"
                (onSelect)="selectPeriod($event)"
                [isChosen]="!isDefaultPeriod">
                <div urn-datepicker-select--no-value><i class="urn-datepicker-icon urn-datepicker-icon-calend"></i> {{'Reisedauer auswählen' | translate}}</div>
                <div urn-datepicker-select--value><i class="urn-datepicker-icon urn-datepicker-icon-calend"></i> {{'Reisedauer' | translate}} {{selectedPeriod ? selectedPeriod.text : ''}}</div>
            </urn-datepicker-select>
        </div>

        <div class="button"
             [class.active]="startDate && endDate"
             (click)="close()">
            {{'Übernehmen' | translate}}
        </div>
    </div>
</div>
