<div class="form-paper-frame-wrapper" [class.form-paper-store-in-progress]="storeFormPaperInProgress">
    <div class="loader-wrapper" *ngIf="storeFormPaperInProgress">
        <urn-preload-spinner [butterfly]="true"></urn-preload-spinner>
    </div>

    <p>{{ descriptionText }}</p>

    <div class="iframe-wrapper">
        <iframe #iframeRef [src]="formPaperUrl"></iframe>
    </div>
</div>
