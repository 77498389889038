<nav>
    <ul>
        <ng-container *ngFor="let tab of tabs; let i = index">
            <li *ngIf="tab.available" (click)="selectTab(tab)" [class.active]="tab.active">
                <div class="nav-item-wrapper">
                    <div class="urn-hotelview-tabs-icon" [ngClass]="iconClassPrefix + iconClasses[i]"></div>
                    <label>{{tab.label | translate}}</label>
                </div>
            </li>
        </ng-container>
    </ul>
</nav>
<section>
    <ng-content></ng-content>
</section>
