<div class="hotel-card" [class.no-options]="!optionsNumber" [class.hotel-info-open]="isHotelInfoOpened">
    <section class="content">
        <div class="main-content">
            <div class="main-content-wrapper">
                <header class="header-container">
                    <div class="category-content">
                        <div class="category-item" *ngFor="let i of hotelCard.stars | generateArray"></div>
                    </div>
                    <h2 class="hotel-name-content">
                        <span class="hotel-name" title="{{hotelCard.title}}">{{hotelCard.title}}</span>
                    </h2>
                    <h3 class="region-content">
                        <span class="region-name">{{hotelCard.city}}, {{hotelCard.country | translate}}</span>
                    </h3>
                </header>

                <div class="hotel-info-container">
                    <div class="picture-container" [class.image-loaded]="imageLoadedSuccessfully">
                        <div class="picture-content">
                            <img src="assets/images/aspect-ratio-placeholders/square.png">
                            <div class="picture-placeholder"></div>
                            <div class="picture-hotel"
                                 urnImagePreloader
                                 [preloadingImage]="hotelCard.image"
                                 [failedImage]="failedImage"
                                 [loadingImage]="''"
                                 (imageLoaded)="handleImageLoaded()"
                            >
                            </div>
                        </div>
                        <div class="rating-content" *ngIf="ratingAvailable">
                            <urn-pie-chart [value]="hotelCard.rating"></urn-pie-chart>
                        </div>
                    </div>

                    <div class="options-content" *ngIf="optionsNumber">
                        <div class="option" *ngFor="let option of hotelCard.options">
                            <span class="urn-hotel-card-icon urn-hotel-card-icon-checked"></span>
                            <span class="option-text">{{option | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hotel-info-button-bar">
                <div class="hotel-info-buttons">
                    <div class="leaflet-icon-container">
                        <div class="leaflet-icon"
                             [ngClass]="{'like': hotelCard.liked, 'dislike': !hotelCard.liked}"
                             (click)="likeButtonClick()"
                        >
                            <span class="urn-hotel-card-icon"
                                  [ngClass]="{
                                      'urn-hotel-card-icon-like-unchecked': !hotelCard.liked,
                                      'urn-hotel-card-icon-like-checked': hotelCard.liked
                                  }"
                            >
                            </span>
                        </div>
                    </div>
                    <div class="hotel-info-button" [class.active]="isHotelInfoOpened" (click)="switchHotelInfo()">
                        <div class="urn-hotel-card-icon urn-hotel-card-icon-info"></div>
                        <label class="button-text">{{'Hotelinfos' | translate}}</label>
                        <div *ngIf="isHotelInfoOpened" class="close-button">
                            <div class="urn-hotel-card-icon urn-hotel-card-icon-close"></div>
                        </div>
                    </div>

                    <div *ngIf="mapAvailable"
                         class="hotel-info-button"
                         [class.active]="isHotelMapOpened"
                         (click)="switchHotelMap()">
                        <div class="urn-hotel-card-icon urn-hotel-card-icon-map"></div>
                        <label class="button-text">{{'In Karte' | translate}}</label>
                        <div *ngIf="isHotelMapOpened" class="close-button">
                            <div class="urn-hotel-card-icon urn-hotel-card-icon-close"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <urn-hotelview
            [hotelCard]="hotelCard"
            [isHotelViewOpened]="isHotelInfoOpened"
            [currentOperatorId]="hotelviewTourOperator"
            [@hotelviewState]="isHotelInfoOpened ? 'visible' : 'hidden'"
            (@hotelviewState.done)="setExpandingAnimationComplete()"
            [expandingAnimationComplete] = "expandingAnimationComplete"
            [hotelviewConfiguration]="hotelviewConfiguration"
            (tourOperatorChange)="onTourOperatorChange($event)"
            >
        </urn-hotelview>
        <div class="map-wrapper" [class.hotel-map-open]="isHotelMapOpened">
            <urn-map [hotelCards]="hotelCardAsArray" *ngIf="isHotelMapOpened"></urn-map>
        </div>
    </section>
    <footer class="submit-container">
        <div class="ticket-border-wrapper">
            <div class="ticket-border-first"></div>
            <div class="ticket-border-last"></div>
        </div>
        <div class="footer-content">
            <div class="prices-wrapper">
                <div class="prices">
                    <div class="price" *ngIf="showPrice">
                        <span class="price-prefix" *ngIf="!totalPriceValidated">{{'ab' | translate}}</span>
                        <span class="price-value">{{hotelCard.price | currency:'EUR':'symbol':'1.2-2'}}
                        </span>
                    </div>
                    <div class="price loader-container" *ngIf="showPriceLoading">
                        <div class="loader"></div>
                    </div>
                    <div class="total-price" (click)="getTotalPrice()" [class.total-price-is-validated]="totalPriceValidated">
                        {{'Gesamtpreis holen' | translate}}
                    </div>
                </div>
            </div>
            <div class="submit-button-wrapper">
                <div class="submit-button" (click)="submitHotel()" [class.large-label]="totalPriceValidated && !validationSuccess">
                    {{(!totalPriceValidated || validationSuccess ? 'Weiter' : 'Gesamtpreis') | translate}}
                </div>
            </div>
            <div class="tour-operator-slider-wrapper" *ngIf="tourOperatorSliderEnabled">
                <urn-tour-operator-slider [availableTourOperators]="availableHotelInfoTourOperators"
                                          [(ngModel)]="hotelviewTourOperator">
                </urn-tour-operator-slider>
            </div>
        </div>
    </footer>
</div>
