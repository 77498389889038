<div class="attributes-filter">
    <h3 class="title">{{'Hoteleigenschaften' | translate}}</h3>

    <div class="attribute-groups">
        <div class="attribute-group" *ngFor="let attributeGroup of attributes">
            <header (click)="setVisibleGroup(attributeGroup)"
                    [class.active]="getGroupVisibilityStatus(attributeGroup) === 'opened'">
                <div class="icon-wrapper">
                    <i class="ura-filter-attributes-icon"
                       [ngClass]="{
                           'ura-filter-attributes-icon-options-opened': getGroupVisibilityStatus(attributeGroup) === 'opened',
                           'ura-filter-attributes-icon-options': getGroupVisibilityStatus(attributeGroup) === 'closed'
                       }">
                    </i>
                </div>
                <h4>{{attributeGroup.name | translate}}</h4>
            </header>
            <div class="attributes" [@attributeOptionsVisible]="getGroupVisibilityStatus(attributeGroup)">
                <urn-checkbox *ngFor="let attribute of attributeGroup.attributes"
                              [(ngModel)]="attribute.selected"
                              [labelText]="translateAttributeCode(attribute.code)"
                              (onChange)="attributeChange(attribute)">
                </urn-checkbox>
            </div>
        </div>
    </div>
</div>
