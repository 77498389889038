<div class="main-container">
    <div class="input-container" (click)="toggleDropdown()">
        <span class="urn-operator-icon urn-operator-icon-arrow-down"
              [hidden]="isOpened && searchText.length > 0">
        </span>
        <span class="urn-operator-icon urn-operator-icon-remove"
              [hidden]="!isOpened || searchText.length === 0"
              (click)="clearInput()">
        </span>
        <input type="text"
               [placeholder]="inputPlaceholder"
               (focus)="inputFocusHandler()"
               (blur)="inputBlurHandler()"
               [(ngModel)]="searchText" />
    </div>

    <div class="dropdown-container" [hidden]="!isOpened">
        <div class="selected-operators-group" *ngIf="showSelectedOperatorsGroup">
            <div class="selected-operators-header">
                <div class="operator-group-title">
                    <div class="title-border"></div>
                    <h4>{{'Deine Auswahl' | translate}}</h4>
                </div>
                <div class="reset-button" (click)="resetSelectedOrganizers()">
                    <i class="urn-operator-icon urn-operator-icon-delete"></i>
                </div>
            </div>

            <div class="operator-list">
                <div class="checkbox-wrapper" *ngFor="let operator of selectedOperators">
                    <urn-checkbox [(ngModel)]="operator.selected" [labelText]="operator.name"></urn-checkbox>
                </div>
            </div>
        </div>

        <div class="operators-group">
            <div class="operator-group-title">
                <div class="title-border"></div>
                <h4>{{'Veranstaltername' | translate}}</h4>
            </div>
            <div class="operator-list">
                <div class="checkbox-wrapper" *ngFor="let operator of operators | searchOperators: searchText">
                    <urn-checkbox [(ngModel)]="operator.selected"
                        [labelText]="operator.name"
                        (onChange)="toggleSelectedOperatorsGroup()">
                    </urn-checkbox>
                </div>
            </div>
        </div>

        <div class="operator-group-button" (click)="hideDropdown()">
            {{'Übernehmen' | translate}}
        </div>
    </div>
</div>
