<div class="leaflet-item" [class.selected]="selected" (click)="selectCard()" (mouseover)="mouseover()">
    <div class="btn-close" (click)="closeCard()">
        <i class="urn-leaflet-icon urn-leaflet-icon-close"></i>
    </div>

    <section class="content">
        <div class="main-content">
            <div class="picture-block">
                <div class="picture-container" [class.image-loaded]="imageLoadedSuccessfully">
                    <div class="picture-content">
                        <img src="assets/images/aspect-ratio-placeholders/square.png">
                        <div class="picture-placeholder"></div>
                        <div
                            class="picture-hotel"
                            urnImagePreloader
                            [preloadingImage]="leafletItem.hotelCard.image"
                            [failedImage]="failedImage"
                            [loadingImage]="loadingImage"
                            (imageLoaded)="handleImageLoaded()">
                        </div>
                    </div>
                    <div class="rating-content" *ngIf="leafletItem.hotelCard.rating > 0">
                        <urn-pie-chart [value]="leafletItem.hotelCard.rating"></urn-pie-chart>
                    </div>
                </div>
            </div>

            <header class="header-container">
                <div class="category-content">
                    <div class="category-item" *ngFor="let i of leafletItem.hotelCard.stars | generateArray"></div>
                </div>
                <h2 class="hotel-name-content">
                    <span class="hotel-name">{{leafletItem.hotelCard.title}}</span>
                </h2>
                <h3 class="region-content">
                    <span class="region-name">
                        {{leafletItem.hotelCard.city}}, {{leafletItem.hotelCard.country | translate}}
                    </span>
                </h3>
            </header>

            <div class="picture-block mobile">
                <div class="picture-container" [class.image-loaded]="imageLoadedSuccessfully">
                    <div class="picture-content">
                        <img src="assets/images/aspect-ratio-placeholders/square.png">
                        <div class="picture-placeholder"></div>
                        <div
                            class="picture-hotel"
                            urnImagePreloader
                            [preloadingImage]="leafletItem.hotelCard.image"
                            [failedImage]="failedImage"
                            [loadingImage]="loadingImage"
                            (imageLoaded)="handleImageLoaded()">
                        </div>
                    </div>
                    <div class="rating-content" *ngIf="leafletItem.hotelCard.rating > 0">
                        <urn-pie-chart [value]="leafletItem.hotelCard.rating"></urn-pie-chart>
                    </div>
                </div>
            </div>

            <div class="options-content mobile">
                <div class="option" *ngFor="let option of leafletItem.hotelCard.options">
                    <span class="urn-hotel-card-icon urn-hotel-card-icon-checked"></span>
                    <span class="option-text">{{option | translate}}</span>
                </div>
            </div>
        </div>
    </section>

    <footer class="submit-container">
        <div class="ticket-border-wrapper">
            <div class="ticket-border-first"></div>
            <div class="ticket-border-last"></div>
        </div>

        <div class="options-content">
            <div class="option" *ngFor="let option of leafletItem.hotelCard.options">
                <span class="urn-hotel-card-icon urn-hotel-card-icon-checked"></span>
                <span class="option-text">{{option | translate}}</span>
            </div>
        </div>

        <div class="price-container">
            <div class="price" *ngIf="showPrice">
                <span class="price-prefix" *ngIf="!totalPriceValidated">{{'ab' | translate}}</span>
                <span class="price-value">{{leafletItem.hotelCard.price | currency:'EUR':'symbol':'1.2-2'}}</span>
            </div>
            <div class="loader-container" *ngIf="showPriceLoading">
                <div class="loader"></div>
            </div>
        </div>

        <div class="total-price" (click)="getTotalPrice()" [class.total-price-is-validated]="totalPriceValidated">
            {{'Gesamtpreis holen' | translate}}
        </div>
        <div class="submit-button-wrapper">
            <div class="submit-button" (click)="submitHotel()">
                {{'Weiter' | translate}}
            </div>
        </div>
    </footer>
</div>
