<div class="offer-page">
    <div class="breadcrumb-wrapper">
        <urn-breadcrumb-menu></urn-breadcrumb-menu>
        <h4 class="offer-count">{{response ? response.count : ''}} {{'Angebote' | translate}}</h4>
    </div>
    <div class="offer-description-content" *ngIf="!noOffersAvailable && !initialLoad && currentType !== 'NF'">
        <urn-offer-description-card [hotelCard]="hotelCard"></urn-offer-description-card>
    </div>
    <div class="sort-container">
        <urn-sort (sortOrderUpdated)="onSortOrderChange($event)" [sortOptions]="sortOptions"></urn-sort>
    </div>
    <div class="offer-page-container">
        <div class="filterbox-container">
            <urn-filter-box></urn-filter-box>
            <urn-recent-offers></urn-recent-offers>
        </div>
        <div class="offer-page-content-container">
            <div class="spinner-wrapper"
                 [class.page-loading]="pageLoading"
                 *ngIf="initialLoad || pageLoading"
                 [ngStyle]="preloadSpinnerStyles">
                <urn-preload-spinner></urn-preload-spinner>
            </div>
            <div class="termins-list" *ngIf="!noOffersAvailable && !initialLoad" [class.page-loading]="pageLoading">
                <div class="termin-item" *ngFor="let offer of offers" [hidden]="isOfferPrevalidationFailed(offer.id)">
                    <urn-offer-card [offer]="offer"></urn-offer-card>
                </div>
            </div>
            <urn-no-offers-message *ngIf="noOffersAvailable && !initialLoad"></urn-no-offers-message>
            <div class="pagination-container">
                <urn-pagination *ngIf="!initialLoad && !noOffersAvailable && countOfPages !== 1"
                    [countOfPages]="countOfPages"
                    [currentPage]="currentPage"
                    (onChange)="onPageChanged($event)"></urn-pagination>
            </div>
        </div>
    </div>
</div>
